import FlashLib from 'flashlib';
import { requestBuyBonusGame } from '../../../api/rest';
import { store } from '../../../index';
import actionsModals from '../../../redux/modals/actions';
import eModalType from '../eModalType';
import actions from '../../../redux/game/actions';
import selectors from '../../../redux/game/selectors';
import { formatCurrency } from '../../../utils/currency';
import { eGameState } from '../../../redux/game/reducer';

export default class ControllerBonusPurchaseModal extends FlashLib.MovieClip {
  constructor(data, $displayItemData) {
    super(data, $displayItemData);
    this.wrapper = this.getChildByName('wrapper');
    this.close = this.wrapper.getChildByName('btnClose');
    this.continue = this.wrapper.getChildByName('btnContinue');
    this.priceText = this.wrapper.getChildByName('amount');
    this.blackout = this.getChildByName('blackout');

    const labelCancel = this.close.getChildByName('popup_bonus_purchase_btn_no');
    const labelContinue = this.continue.getChildByName('popup_bonus_purchase_btn_continue');
    labelCancel.correctPosition('center', 'center');
    labelContinue.correctPosition('center', 'center');

    this.close.onClick = this.onCloseClicked;
    this.visible = false;
    this.interactive = true;
    this.continue.onClick = this.onContinueClick;
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }
  onResize = () => {
    // const width = Math.min(window.innerWidth, window.outerWidth);
    // const height = Math.min(window.innerHeight, window.outerHeight);
    // const isHorizontalOrientation = (width - height) > 0;
    // const widthGame = constants.GAME_WIDTH;
    // const heightGame = constants.GAME_HEIGHT;
    //
    // if (PIXI.utils.isMobile.any && isHorizontalOrientation) {
    //   this.wrapper.pivot.x = 0;
    //   this.wrapper.x = this.wrapper.displayData.x;
    //   this.wrapper.pivot.y = 0;
    //   this.wrapper.y = this.wrapper.displayData.y;
    //   this.wrapper.scale.set(1);
    //
    // } else if (PIXI.utils.isMobile.any) {
    //   const viewWidthGame = heightGame * width / height;
    //   const scaleGame = viewWidthGame / widthGame;
    //
    //   this.wrapper.pivot.x = this.wrapper.displayData.width / 2;
    //   this.wrapper.x = this.displayData.width / 2;
    //   this.wrapper.pivot.y = this.wrapper.displayData.height / 2;
    //   this.wrapper.y = this.displayData.height / 2;
    //   this.wrapper.scale.set(scaleGame * 1.8);
    //
    // }

  }

  updatePrice = () => {
    const state = store.getState();
    const amount = selectors.getBonusPrices(state);
    const currencyInfo = selectors.getCurrencyInfo(state);

    this.priceText.text = formatCurrency(amount, currencyInfo.decimal) + ` ${currencyInfo.currency}`;
  };

  show() {
    this.visible = true;
    this.updatePrice();
    this.blackout.show(true, 0.2, 0, 0.7);
  }

  onContinueClick = () => {
    const state = store.getState();
    const amount = selectors.getBonusPrices(state);
    const balance = selectors.getBalance(state);
    if (balance < amount) {
      window.OPWrapperService.showError(window.OPWrapperService.errors.INSUFFICIENT_BALANCE_CLIENT.CODE);
      return;
    }
    OPWrapperService.ControllerStatistic.bet = amount;
    OPWrapperService.realityCheck.blockedRealityCheck = true;
    requestBuyBonusGame().then(this.onResponse);
  }

  onResponse = () => {
    this.onCloseClicked();
    store.dispatch(actionsModals.closeModal({ type: eModalType.EMT_BONUS_PURCHASE }));
    store.dispatch(actions.setGameState(eGameState.EGS_BUY_BONUS_GAME));
    store.dispatch(actions.tryShowBonusGame());
  }

  onCloseClicked = () => {
    this.visible = false;
  }
}
