import FlashLib from 'flashlib';
import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import { storeObserver } from '../../index';
import selectors from '../../redux/modals/selectors';
import gameSelectors from '../../redux/game/selectors';
import eModalType from '../popups/eModalType';

export default class ControllerBackground extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.eAnimationName = {
      EAN_IDLE: 'idle',
      EAN_ACTION: 'action'
    };

    if (!PIXI.utils.isMobile.any){
      this.initAnimation();
      storeObserver.addListener(selectors.getModalData(eModalType.EMT_WIN_BIG), this.onModalWinDataChanged);
    } else {
      window.OPWrapperService.eventManager.add(
        window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
        this.onResize,
        this
      );
    }
    storeObserver.addListener(gameSelectors.getBonusGameShowed, this.onBonusGameShowed);
  }

  onResize = (data) => {
    if (data.isMobile && data.isLandscape) {
      this.goToFrame(2);

    } else if (data.isMobile && data.isPortrait) {
      this.goToFrame(1);
    }
  }
  initAnimation = () => {
    this.animation = animationsLoader.createAnimation(eAnimationType.EAT_BACKGROUND);
    this.animation.x = this.displayData.width / 2;
    this.animation.y = this.displayData.height / 2;
    this.animation.state.setAnimation(0, this.eAnimationName.EAN_IDLE, true);
    this.activeAnimation = this.eAnimationName.EAN_IDLE;

    this.addChildAt(this.animation, this.children.length - 1);
  }

  onBonusGameShowed = (showed) => {
    if (this.animation) {
      showed ? setTimeout(() => this.animation.visible = false, 2000) : this.animation.visible = true ;
    }
  }

  onModalWinDataChanged = (isShowWin) => {
    if (isShowWin.visible) {
      if (this.activeAnimation === this.eAnimationName.EAN_ACTION) return;
      this.activeAnimation = this.eAnimationName.EAN_ACTION;
    } else {
      if (this.activeAnimation === this.eAnimationName.EAN_IDLE) return;
      this.activeAnimation = this.eAnimationName.EAN_IDLE;
    }
    this.animation.state.setAnimation(0, this.activeAnimation, true);
  }
}
