import { createActions } from 'redux-actions';

export default createActions(
  {
    SET_DATA: (type) => type,
    SET_NEW_GAME: (data) => data,
    INCREMENT_BET: (data) => data,
    DECREMENT_BET: (data) => data,
    SET_MIN_BET: (data) => data,
    SET_MAX_BET: (data) => data,
    SET_BET: (data) => data,
    SET_CURRENCY_INFO: (data) => data,
    SET_GAME_STATE: (data) => data,
    SET_BALANCE: (data) => data,
    START_AUTOSPIN: (data) => data,
    STOP_AUTOSPIN: (data) => data,
    SET_FREESPIN_MODE: (data) => data,
    SET_STOPPING_REEL: (data) => data,
    SET_SCREEN_MODE: (data) => data,
    TRY_SHOW_BONUS_GAME: (data) => data,
    CLOSE_BONUS_GAME: (data) => data,
    SET_BONUS_GAME: (data) => data,
    UPDATE_REAL_CURRENT_ENEMY: (data) => data,
    SET_LEADERBOARD: (data) => data
  },
  {
    prefix: 'game',
    namespace: '.',
  },
);
