import selectors from '../../../redux/game/selectors';
import { store } from '../../../index';
import actions from '../../../redux/game/actions';
import { eGameState } from '../../../redux/game/reducer';
import { sleep } from '../../../utils/sleep';
import constants from '../../../constants';
import controllerSounds, { eSoundType } from '../../sounds/controllerSounds';
import ControllerBaseBehaviour from './controllerBaseBehaviour';

export default class ControllerSlotNormalBehaviour extends ControllerBaseBehaviour {
  constructor(slotController) {
    super(slotController);
    this._slotController = slotController;
  }

  endSpin() {
    const state = store.getState();
    const winLines = selectors.getWinLine(state);
    const winAmount = selectors.getWinAmount(state);
    const betAmount = selectors.getBetAmount(state);
    const isChangedCurrentEnemy = selectors.getIsChangedCurrentEnemy(state);
    const myHealth = selectors.getMyHeals(state);
    const bonusGameAvailable = selectors.getBonusGameAvailable(state);
    const isAutoSpin = selectors.getIsAutospin(state);

    this._slotController
      .startWinAnimation(winLines, true)
      .then(async ({ delay, isWin }) => {
        if (bonusGameAvailable) controllerSounds.playSound(eSoundType.EST_SCATTER);
        if (bonusGameAvailable || isAutoSpin) await sleep(delay - 1000);
        store.dispatch(actions.setGameState(eGameState.EGS_SHOW_WIN));
        if ( winAmount > 0 && winAmount < constants.MULTIPLIER_BIG_WIN * betAmount) {
          if (isAutoSpin) await sleep(300);
        } else if (winAmount > 0) {
          await sleep(7000);
        }
        store.dispatch(actions.tryShowBonusGame());
        store.dispatch(actions.setGameState(eGameState.EGS_UPDATE_HEALTH));
        winAmount > constants.MULTIPLIER_BIG_WIN * betAmount && await sleep(1000);
        if (isChangedCurrentEnemy || myHealth === 0) {
          await sleep(3500);
        }

        this.updateFreeBets();
        if (!bonusGameAvailable) store.dispatch(actions.setGameState(eGameState.EGS_READY_TO_PLAY));
      });
  }
}
