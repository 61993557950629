import FlashLib from 'flashlib';

function centring () {
  this.centringX();
  this.centringY();
}

function centringX () {
  this.pivot.x = this.displayData.width / 2;
  this.x = this.displayData.x + this.displayData.width / 2;
}

function centringY () {
  this.pivot.y = this.displayData.height / 2;
  this.y = this.displayData.y + this.displayData.height / 2;
}

function anchorDefault () {
  this.anchorDefaultX();
  this.anchorDefaultY();
}

function anchorDefaultX () {
  this.pivot.x = 0;
  this.x = this.displayData.x;
}

function anchorDefaultY () {
  this.pivot.y = 0;
  this.y = this.displayData.y;
}

FlashLib.MovieClip.prototype.centring = centring;

FlashLib.MovieClip.prototype.centringX = centringX;

FlashLib.MovieClip.prototype.centringY = centringY;

FlashLib.MovieClip.prototype.anchorDefault = anchorDefault;

FlashLib.MovieClip.prototype.anchorDefaultX = anchorDefaultX;

FlashLib.MovieClip.prototype.anchorDefaultY = anchorDefaultY;

FlashLib.TextField.prototype.centring = centring;

FlashLib.TextField.prototype.centringX = centringX;

FlashLib.TextField.prototype.centringY = centringY;

FlashLib.TextField.prototype.anchorDefault = anchorDefault;

FlashLib.TextField.prototype.anchorDefaultX = anchorDefaultX;

FlashLib.TextField.prototype.anchorDefaultY = anchorDefaultY;
