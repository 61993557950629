export default {
  ECT_SIDEBAR: 'controller_sidebar',
  ECT_HONESTY: 'controller_honesty',
  ECT_TOURNAMENTS: 'controller_tournaments',
  ECT_TOURNAMENTS_START_SCREEN: 'controller_tournaments_start_screen',
  ECT_MODAL_TOURNAMENT_END: 'controller_modal_tournament_end',
  // ECT_MODAL_TOURNAMENT_DETAILS: 'controller_modal_tournament_details',
  ECT_NOTIFICATIONS: 'controller_notifications',
  ECT_ROUNDS_HISTORY: 'controller_rounds_history',
}
