import eSymbolType from './eSymbolType';

export default {
  '10': eSymbolType.EST_BONUS,
  '9': eSymbolType.EST_SCATTER,
  '8': eSymbolType.EST_LOW_1,
  '7': eSymbolType.EST_LOW_0,
  '6': eSymbolType.EST_LOW_3,
  '5': eSymbolType.EST_LOW_2,
  '4': eSymbolType.EST_HIGH_2,
  '3': eSymbolType.EST_HIGH_1,
  '2': eSymbolType.EST_HIGH_0,
  '1': eSymbolType.EST_WILD,
};
