import FlashLib from 'flashlib';

export default class ControllerUi extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize = (data) => {
    if (!data.isMobile) return;
    const frame = data.isPortrait ? 1 : 2;
    this.goToFrame(frame);
  }
}
