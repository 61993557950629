import selectors from '../../redux/game/selectors';
import { storeObserver } from '../../index';
import { store } from '../../index';
import actions from '../../redux/game/actions';
import { eGameState } from '../../redux/game/reducer';
import { formatCurrency } from '../../utils/currency';
import { formTitleStyle } from '../../fontStyles';

export default class ControllerBetSelector {

  constructor(container) {
    this.init(container);
    this.dispatch = store.dispatch;

    storeObserver.addListener(selectors.getGameState, this.onGameStateChanged);
    this.currencyInfo = storeObserver.addListenerAndGetValue(selectors.getCurrencyInfo, this.currencyInfoChanged);
    const betAmount = storeObserver.addListenerAndGetValue(selectors.getBetAmount, this.onBetAmountChanged);
    this.onBetAmountChanged(betAmount);

    const freespinsEnabled = selectors.getFreespinsEnabled(store.getState());
    this.setButtonsEnable(
      !freespinsEnabled
      && !window.OPWrapperService.freeBetsController.isActive
      && !window.OPWrapperService.freeBetsController.isFirstFreeBet
    );
  }

  init = (container) => {

    this.buttonMinus = container.getChildByName('buttonMinus');
    this.buttonMinus.addListener('pointertap', this.onMinusClicked);
    this.buttonPlus = container.getChildByName('buttonPlus');
    this.buttonPlus.addListener('pointertap', this.onPlusClicked);

    this.labelBet = container.getChildByName('label');
    this.titleBet = container.getChildByName('game_cash_bet');
    if (this.titleBet) this.titleBet.style = { ...this.titleBet.style, ...formTitleStyle };
  }

  setButtonsEnable = (value) => {
    this.buttonMinus.enabled = value;
    this.buttonPlus.enabled = value;
  };

  onGameStateChanged = (gameState) => {
    const state = store.getState().game;
    this.setButtonsEnable(
      gameState === eGameState.EGS_READY_TO_PLAY
      && !state.autospin
      && !window.OPWrapperService.freeBetsController.isActive
      && !window.OPWrapperService.freeBetsController.isFirstFreeBet
    );
  };

  onBetAmountChanged = (value) => {
    const state = store.getState();
    let currencyInfo = selectors.getCurrencyInfo(state);
    this.labelBet.text = `${formatCurrency(value, this.currencyInfo.decimal)} ${currencyInfo.currency.toUpperCase()}`;
  };

  currencyInfoChanged = (value) => {
    this.currencyInfo = value;
  };

  onPlusClicked = () => {
    this.dispatch(actions.incrementBet());
  };

  onMinusClicked = () => {
    this.dispatch(actions.decrementBet());
  };

  onChangeActiveBetInOPWrapper = (index) => {
    this.dispatch(actions.setBet(index));

  }

  // let intervalPlusPressed = useRef(null);
  onPlusPressed = () => {
    intervalPlusPressed.current = setInterval(() => dispatch(actions.incrementBet()), 100);
  };

  onPlusUnpressed = () => {
    clearInterval(intervalPlusPressed.current);
  };

  // let intervalMinusPressed = useRef(null);
  onMinusPressed = () => {
    intervalMinusPressed.current = setInterval(() => dispatch(actions.decrementBet()), 100);
  };

  onMinusUnpressed = () => {
    clearInterval(intervalMinusPressed.current);
  };
}
