export const greenShadow = {
  dropShadow: true,
  dropShadowAlpha: 0.7,
  dropShadowAngle: 1.3,
  dropShadowColor: '#016f00',
  dropShadowDistance: 6,
};

// legacy
export const greenStroke = {
  stroke: '#3c6e24',
  strokeThickness: 8,
};

export const greenStrokeShadow = {
  stroke: '#3c6e24',
  strokeThickness: 8,
  dropShadow: true,
  dropShadowAngle: 1.5,
  dropShadowBlur: 1,
  dropShadowColor: '#1d3d0d',
  dropShadowDistance: 10,
};

export const yellowStrokeGradient = {
  miterLimit: 0,
  dropShadow: true,
  dropShadowAngle: -1.5,
  dropShadowColor: "#ee7214",
  dropShadowDistance: 2,
  letterSpacing: 2,
  fill: [
    "#fcff1f",
    "#f5efa9",
    "#bc894b",
    "#bc894b",
    "#e6d237",
    "#f2ec98"
  ],
  fillGradientStops: [
    0.4,
    0.55,
    0.69,
    0.74,
    0.9,
    0.92,

  ],
  lineJoin: "round",
  stroke: "#643d03",
  strokeThickness: 5
};

export const topStarsBronze = {
  fill: [
    "#ffd7ce",
    "#f5927c",
    "#b8543d",
    "#f28f79"
  ],
  fillGradientStops: [
    0.27,
    0.5,
    0.85
  ],
  lineJoin: "round",
  stroke: "#450d00",
  strokeThickness: 10
};
export const topStarsSilver = {
  fill: [
    "#fefce6",
    "#adadad",
    "#868686",
    "#dadada"
  ],
  fillGradientStops: [
    0.27,
    0.5,
    0.85
  ],
  lineJoin: "round",
  stroke: "#202020",
  strokeThickness: 10
};
export const topStarsGold = {
  fill: [
    "#f2ec98",
    "#e6d237",
    "#d09a0a",
    "#d9ca6c"
  ],
  fillGradientStops: [
    0.27,
    0.5,
    0.85
  ],
  lineJoin: "round",
  stroke: "#4d0200",
  strokeThickness: 10
};

export const winAmountCounterStyle = {
  anchor: {
    x: 0.5,
    y: 0.5
  },
  fontName: 'BoxerCountFont',
  fontSize: 190,
  align: 'center'
};

export const freeSpinsPanelValue = {
  ...yellowStrokeGradient,
  align: 'center',
  fontSize: 52
};

export const formTitleStyle = {
  dropShadow: true,
  dropShadowAngle: 1.6,
  dropShadowBlur: 1,
  dropShadowColor: '#e8ab02',
  dropShadowDistance: 4,
  fill: '#362a35',
  fontSize: 36
};

export const whiteYeelowOrange = {
  fill: [
    'white',
    'yellow',
    '#ffa200'
  ],
  fillGradientStops: [
    0.4,
    0.5,
    0.7
  ]
};

export const whiteBeigeGradient = {
  dropShadow: true,
  dropShadowAngle: 1.5,
  dropShadowColor: "#b65a00",
  dropShadowDistance: 6,
  fill: [
    "#ffd89b",
    "#fff1da"
  ],
};
