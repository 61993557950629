export default class Queue {
  constructor(executionCallback) {
    this._queue = [];
    this._inProgress = false;
    this._paused = false;
    this._resolvePause = null;
    this._pausePromise = null;
    this._executionCallback = executionCallback;
  }

  add(item) {
    this._queue.push(item);
    this._tryStart();
  }

  _tryStart() {
    if (this._paused || this._inProgress || !this._queue.length) return;
    this._start();
  }

  async _start() {
    this._inProgress = true;
    while (this._queue.length) {
      if (this._paused) await this._pausePromise;

      const item = this._queue.shift();
      await this._execute(item);
    }

    this._inProgress = false;
  }

  async _execute(item) {
    if (this._executionCallback) await this._executionCallback(item);
  }

  pause() {
    if (this._paused) return;

    this._paused = true;
    this._pausePromise = new Promise(resolve => {
      this._resolvePause = resolve;
    })
  }

  continue() {
    this._paused = false;
    this._resolvePause && this._resolvePause();
    this._tryStart();
  }
}
