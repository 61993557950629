import FlashLib from 'flashlib';
import { Bounce, TimelineLite } from 'gsap';
import { whiteBeigeGradient } from '../../fontStyles';
import controllerSounds, { eSoundType } from '../sounds/controllerSounds';

export default class ControllerBonusGameMultipliers extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.mobilePortretEndPosition = { x: 1170, y: 1890 };
    this.mobileLandscapeEndPosition = { x: 422, y: 775 };
    this.desktopEndPosition = { x: 385, y: 120 };

    this.init();
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize = (data) => {
    if (data.isMobile && data.isLandscape) {
      this.endPosition.x = this.mobileLandscapeEndPosition.x;
      this.endPosition.y = this.mobileLandscapeEndPosition.y;
      this.tryRestartAnimation();
    } else if (data.isMobile && data.isPortrait) {
      this.endPosition.x = this.mobilePortretEndPosition.x;
      this.endPosition.y = this.mobilePortretEndPosition.y;
      this.tryRestartAnimation();
    }
  }

  init = () => {
    this.alpha = 0;
    this.visible = false;
    this.multiplier = this.getChildByName('multiplier');
    this.multiplier.style = { ...this.multiplier.style, ...whiteBeigeGradient };
    this.multiplier.visible = false;
    this.endPosition = { ...this.desktopEndPosition };
  }

  showAmimation = (multiplierType, isInternalCall = false) => {
    this.multiplierType = multiplierType;
    this.multiplier.text = `×${multiplierType}`;
    this.multiplier.visible = true;
    const promise = new Promise(resolve => {
      if (!isInternalCall) this.resolve = resolve;
    });
    this.animation = new TimelineLite({});
    this.animation
      .set(this, { visible: true })
      .set(this, { x: this.displayData.x, y: this.displayData.y })
      .call(() => controllerSounds.playSound(eSoundType.EST_WIN_MULTIPLIER))
      .fromTo(this, 0.6, { alpha: 0 }, { alpha: 1 }, 'show')
      .to(this.scale, 0.6, { x: 1.2, y: 1.2, ease: Bounce.easeOut }, 'show')
      .to(this.scale, 0.6, { x: 0, y: 0 }, 'move')
      .to(this, 0.6, { alpha: 0 }, 'move')
      .to(this, 0.6, this.endPosition, 'move')
      .set(this.multiplier, { visible: false })
      .set(this, { visible: false })
      .call(this.resolve);
    return promise;
  };

  tryRestartAnimation = () => {
    if (this.multiplierType && this.animation && this.animation.isActive()) {
      this.animation.kill();
      this.showAmimation(this.multiplierType, true);
    }
  }
}
