export default {
  EST_LOW_0: 'low_0',
  EST_LOW_1: 'low_1',
  EST_LOW_2: 'low_2',
  EST_LOW_3: 'low_3',
  EST_HIGH_0: 'high_0',
  EST_HIGH_1: 'high_1',
  EST_HIGH_2: 'high_2',
  EST_SCATTER: 'scatter',
  EST_BONUS: 'bonus',
  EST_WILD: 'wild'
};
