const getBalance = (state) => state.game.balance;
const getBetAmount = (state) => state.game.betAmount;
const getCurrentBetIndex = (state) => state.game.currentBetIndex;
const getBets = (state) => state.game.bets;
const getGameState = (state) => state.game.gameState;
const getGameScreen = (state) => state.game.gameScreen;
const getCurrencyType = (state) => state.game.currency;
const getWinAmount = (state) => state.game.winAmount;
const getLastWinAmount = (state) => state.game.lastWinAmount;
const getCurrencyInfo = (state) => state.game.currencyInfo;
const getBetMinMax = (state) => ({ min: state.game.betMin, max: state.game.betMax });
const getStopReels = (state) => state.game.stopReels;
const getWinLine = (state) => state.game.winLine;
const getPaytable = (state) => state.game.paytable;
const getFreespinsEnabled = (state) => state.game.freespinsEnabled;
const getFreespinsCount = (state) => state.game.freespinsCount;
const getFreespinsTotalWin = (state) => state.game.totalWinFS;
const getIsLastFreespin = (state) => state.game.isLastFreespin;
const getBonusPrices = (state) => state.game.bonusPrices[state.game.currentBetIndex];
const getMyHeals = (state) => state.game.myHeals;
const getEnemyHeals = (state) => state.game.enemyHeals;
const getEnemyMaxHeals = (state) => state.game.enemyMaxHeals;
const getBonusGameShowed = (state) => state.game.bonusGameShowed;
const getBonusGameAvailable = (state) => state.game.bonusGameAvailable;
const getBonusGameTotal = (state) => state.game.bonusGameTotalWin;
const getLevel = (state) => state.game.level;
const getMyPosition = (state) => state.game.myPosition;
const getExp = (state) => state.game.exp;
const getDifferenceExp = (state) => state.game.differenceExp;
const getProgressToNextLevel = (state) => state.game.progressToNextLevel;
const getLevelMultiplier = (state) => state.game.levelMultiplier;
const getCurrentEnemy = (state) => state.game.currentEnemy;
const getEnemyChanged = (state) => state.game.enemyChanged;
const getIsChangedCurrentEnemy = (state) => state.game.isChangedCurrentEnemy;
const getRealCurrentEnemy = (state) => state.game.realCurrentEnemy;
const getMultiplyersList = (state) => state.game.multiplyersList;
const getBonusGameField = (state) => state.game.bonusGameField;
const getTargetMultiplyer = (state) => state.game.targetMultiplier;
const getTargetMultiplierIndex = (state) => state.game.targetMultiplierIndex;
const getLeaderboardInfo = (state) => state.game.leaderboardInfo;
const getIsAutospin = (state) => state.game.autospin;
const getCurrentRound = (state) => state.game.currentRound;

const getAvailableSymbols = (index) => (state) => state.game.availableSymbols[index];

export default {
  getBalance,
  getBetAmount,
  getCurrentBetIndex,
  getBets,
  getGameState,
  getGameScreen,
  getCurrencyType,
  getWinAmount,
  getLastWinAmount,
  getCurrencyInfo,
  getBetMinMax,
  getStopReels,
  getWinLine,
  getPaytable,
  getFreespinsEnabled,
  getFreespinsCount,
  getFreespinsTotalWin,
  getAvailableSymbols,
  getBonusPrices,
  getMyHeals,
  getEnemyHeals,
  getEnemyMaxHeals,
  getBonusGameShowed,
  getBonusGameAvailable,
  getBonusGameTotal,
  getLevel,
  getMyPosition,
  getExp,
  getDifferenceExp,
  getProgressToNextLevel,
  getLevelMultiplier,
  getCurrentEnemy,
  getIsChangedCurrentEnemy,
  getRealCurrentEnemy,
  getMultiplyersList,
  getTargetMultiplyer,
  getTargetMultiplierIndex,
  getLeaderboardInfo,
  getBonusGameField,
  getIsAutospin,
  getIsLastFreespin,
  getEnemyChanged,
  getCurrentRound
};
