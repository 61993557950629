import FlashLib from 'flashlib';
import { TweenMax } from 'gsap';
import { store, storeObserver } from '../../index';
import actions from '../../redux/game/actions';
import { eScreenMode } from '../../enums';
import selectors from '../../redux/game/selectors';
import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import eMultipliersAnimation from './eMultipliersAnimation';
import { eGameState } from '../../redux/game/reducer';
import actionsModal from '../../redux/modals/actions';
import eModalType from '../popups/eModalType';
import actionsModals from '../../redux/modals/actions';
import controllerSounds, { eSoundType } from '../sounds/controllerSounds';

export default class ControllerBonusGame extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.freeBetsController = window.OPWrapperService.freeBetsController;
    this.init();
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  init = () => {
    this.interactive = true;
    this.ring = this.getChildByName('ring');
    this.ring.centring();
    this.blackout = this.children[0];
    if (PIXI.utils.isMobile.any) this.background = this.ring.getChildByName('background');
    this.multipliersContainer = this.ring.getChildByName('multipliers_container');
    this.multipliersHistoryContainer = this.ring.getChildByName('multipliers_history_container');
    this.multipliers = this.multipliersHistoryContainer.getChildByName('multipliers');
    this.multipliers.text = '';
    this.multipliersCount = 0;
    this.totalWin = this.ring.getChildByName('totalWin');
    this.totalWinAmount = this.totalWin.getChildByName('totalWinAmount');
    this.field = this.ring.getChildByName('field');
    this.boxers = this.ring.getChildByName('boxers');
    this.initAnimations();
    this.visible = false;
    this.showed = false;
    this.addListeners();

  }

  initAnimations = () => {
    this.ringAnimation = animationsLoader.createAnimation(eAnimationType.EAT_BONUS_GAME_MULTIPLIERS);
    this.ringAnimation.x = this.ring.displayData.width / 2;
    this.ringAnimation.y = this.ring.displayData.height / 2;
    this.ringAnimation.state.setAnimation(0, eMultipliersAnimation.EMA_RING_IDLE, true);
    this.ringAnimation.visible = false;
    this.ringAnimation.stateData.defaultMix = 0.2;

    this.ring.addChildAt(this.ringAnimation, 1);
  }

  addListeners = () => {
    const bonusGameShowed = storeObserver.addListenerAndGetValue(selectors.getBonusGameShowed, this.onBonusGameShowed);
    this.onBonusGameShowed(bonusGameShowed);
    storeObserver.addListenerAndGetValue(selectors.getGameScreen, this.onScreenModeChanged)
  }

  onBonusGameShowed = (bonusGameshowed) => {
    const screenMode = selectors.getGameScreen(store.getState());
    const showed = bonusGameshowed && screenMode !== eScreenMode.START;
    if (showed === this.showed ) return;
    this.showed = showed;
    if (showed) {
      store.dispatch(actionsModals.showModal({ type: eModalType.EMT_WON_BONUS_GAME }));
      setTimeout(() => {
        this.show();
      }, 500);
    } else {
      this.hide();
    }
  }

  onScreenModeChanged = (screenMode) => {
    const bonusGameshowed = selectors.getBonusGameShowed(store.getState());
    const showed = bonusGameshowed && screenMode !== eScreenMode.START;
    if (showed === this.showed ) return;
    this.showed = showed;
    showed ? this.show() : this.hide();
  }

  start = () => {
    this.ringAnimation.state.setAnimation(0, eMultipliersAnimation.EMA_RING_IDLE, true);
    this.field.start(this.onStop);
    this.boxers.showStart();
  }

  onStop = (multiplier) => {
    this.ringAnimation.state.setAnimation(0, eMultipliersAnimation.EMA_RING_WIN, true);
    if (multiplier !== 0) {
      this.boxers.showWin()
        .then(() => {
          this.setTotalWinAmount();
          return this.multipliersContainer.showAmimation(multiplier);
        })
        .then(() => {
          this.addMultiplier();
          this.start();
        });
    } else {
      this.boxers.showExit(() => store.dispatch(actionsModal.showModal({ type: eModalType.EMT_TOTAL_WIN })));
    }

  }

  onResize = (data) => {
    if (data.isMobile && data.isLandscape) {
      this.background.goToFrame(2);
      this.multipliersHistoryContainer.scale.set(this.multipliersHistoryContainer.displayData.scaleX);
      this.multipliersHistoryContainer.x = this.multipliersHistoryContainer.displayData.x;
      this.multipliersHistoryContainer.y = this.multipliersHistoryContainer.displayData.y;
      this.ring.anchorDefault();
      this.ring.scale.set(this.ring.displayData.scaleX);
    } else if (data.isMobile && data.isPortrait) {
      this.background.goToFrame(1);
      this.multipliersHistoryContainer.scale.set(1);
      this.ring.centring();
      this.ring.scale.set(0.95);
      this.multipliersHistoryContainer.x = 900;
      this.multipliersHistoryContainer.y = 1820;
    }
  }

  addMultiplier = (value) => {
    const targetMultiplier = value || selectors.getTargetMultiplyer(store.getState());
    this.multipliers.text += `${this.multipliersCount === 0 ? '' : this.multipliersCount % 5 === 0 ? '\n' : ' '}×${targetMultiplier}`;
    this.multipliersCount++;
  }

  setMultiplierList = () => {
    const state = store.getState();
    const multipliersList = selectors.getMultiplyersList(state);
    this.multipliersCount = 0;
    this.multipliers.text = '';
    multipliersList.forEach(multiplier => {
      this.addMultiplier(multiplier);
    });
  }

  setTotalWinAmount = () => {
    const state = store.getState();
    const currency = selectors.getCurrencyInfo(state).currency;
    const amount = selectors.getBonusGameTotal(state);
    this.totalWinAmount.text = amount === 0 ? '' : `${amount} ${currency}`;
  }

  show = () => {
    store.dispatch(actions.setScreenMode(eScreenMode.BONUS_GAME));
    this.visible = true;
    this.alpha = 1;
    this.reset();
    controllerSounds.replaceSFX(eSoundType.EST_SFX_BONUS_GAME);
  }

  reset = () => {
    this.ringAnimation.visible = true;
    this.boxers.showIdle();
    this.field.clearAnimations();
    this.field.createField();
    this.setTotalWinAmount();
    this.setMultiplierList();
    setTimeout(this.start, 3000);

  }

  hide = () => {
    TweenMax.to(this, 0.3, { alpha: 0, onComplete: this.hideEnd });
    controllerSounds.replaceSFX(eSoundType.EST_SFX);
  }

  hideEnd = () => {
    this.updateFreeBets();
    this.visible = false;
    this.ringAnimation.visible = false;
    store.dispatch(actions.setScreenMode(eScreenMode.GAME));
    store.dispatch(actions.setGameState(eGameState.EGS_READY_TO_PLAY));
  }

  updateFreeBets() {
    const state = store.getState().game;
    this.freeBetsController.updateTotalWin();
    if (!state.freespinsEnabled && !state.bonusGameShowed) {
      this.freeBetsController.show();
      window.OPWrapperService.realityCheck.blockedRealityCheck = false;
    }
    if (
      (this.freeBetsController.isFirstFreeBet || this.freeBetsController.isLastFreeBet || window.OPWrapperService.realityCheck.blockedGame)
      && state.autospin
    ) {
      store.dispatch(actions.stopAutospin());
      store.dispatch(actions.setGameState(eGameState.EGS_STOP_AUTOSPIN));
    }
  }
}

