import {
  OP_WRAPPER_LOCALIZATION_PREFIX,
  OP_WRAPPER_TOURNAMENTS_LOCALIZATION_PREFIX,
  SOCIAL_LANGUAGE_KEY
} from '../utils/constants';
import en from '../assets/i18n/en.json';
import enSocial from '../assets/i18n/en-social.json';
import Event from '../events/event';

export default new class LocalizationManager {

  constructor() {
    this.requestUrl = 'https://i18n.onlyplaygames.net/localizations/${bundle}/${lang}.json';
    this.fallbackRequestUrl = 'https://i18n.onlyplay.net/localizations/${bundle}/${lang}.json';
    this.wrapperTranslationsServerKey = 'wrapperservice';
    this.wrapperLocalizationsKey = OP_WRAPPER_LOCALIZATION_PREFIX;
    this.localizations = null;
    this.fallbackLocalization = null;
    this.currentLanguage = '';
    this.fallbackLanguage = 'en';
    this.localization = null;
    this.localizationChangedCallbacks = [];
    this.textFields = [];
    this.images = [];
    this.onTournamentLocalizationReceived = new Event();
  }

  init(localizations, currentLang, gameBundle, fallbackLang = this.fallbackLanguage) {
    this.gameBundle = gameBundle;
    this.localizations = localizations;
    this.currentLanguage = currentLang;
    this.localization = { ...localizations[currentLang], [this.wrapperLocalizationsKey]: en };

    this.fallbackLocalization = { ...localizations[fallbackLang], [this.wrapperLocalizationsKey]: en };
  }

  _update(lang) {
    this.currentLanguage = lang;
    this.localization = this.localizations[this.currentLanguage];
  }

  async changeLocalization(lang) {
    const isSocial = lang === SOCIAL_LANGUAGE_KEY;

    if (isSocial) {
      const fallback = this.localizations[SOCIAL_LANGUAGE_KEY];
      if (!fallback) {
        return window.OPWrapperService.showError(window.OPWrapperService.errors.SOCIAL_FALLBACK_NOT_SPECIFIED.CODE);
      }

      this.fallbackLocalization = { ...fallback, [this.wrapperLocalizationsKey]: enSocial }
    }

    const haveGameTranslations = await this._tryLoadGameLang(lang);
    const haveWrapperTranslations = await this._tryLoadWrapperLang(lang);

    if (!haveGameTranslations && this.gameBundle) {
      console.error('Can\'t download language for game ', lang);
      if (isSocial) this.localization = { [this.wrapperLocalizationsKey]: this.localization[this.wrapperLocalizationsKey] };
    }
    if (!haveWrapperTranslations) {
      console.error('Can\'t download language for wrapper', lang);
      if (isSocial) this.localization = Object.fromEntries(Object.keys(this.localization).reduce((acc, key) => {
        if (key !== this.wrapperLocalizationsKey) acc.push([key, this.localization[key]]);
        return acc;
      }, []));
    }

    if (!haveGameTranslations && !haveWrapperTranslations) {
      this.executeLocalizationChangeCallbacks();
      return;
    }

    this._update(lang);

    this.textFields.forEach((item) => {
      this.setTranslatedText(item);
    });

    this.images.forEach((item, index) => {
      if (!item.image.parent) {
        this.images.splice(index, 1);
      } else {
        this.setTranslatedImage(item);
      }
    });

    this.executeLocalizationChangeCallbacks();
  }

  executeLocalizationChangeCallbacks() {
    this.localizationChangedCallbacks.forEach((callbackData) => {
      callbackData.function.call(callbackData.context, this.currentLanguage);
    });
  }

  addTextField(textField, identificator, params) {
    let item = {
      textField: textField,
      identificator: identificator ? identificator : textField.name,
      params: params,
      baseStyle: Object.assign({}, textField.style),
      basePosition: Object.assign({}, textField.position)
    };
    this.setTranslatedText(item);

    let index = this.getTextFieldIndex(textField);
    if (index === -1) {
      this.textFields.push(item);
    } else {
      this.textFields[index] = item;
    }
  }

  setTranslatedText(item) {
    if (!this.localization && !this.fallbackLocalization) {
      return;
    }

    let text = '';
    if (item.textField && !item.textField._destroyed &&
      ((this.localization && this.localization[item.identificator]) || this.fallbackLocalization[item.identificator])) {
      text = this.getLocalizedText(item.identificator, item.params);
    }
    if (text !== '' && typeof text === 'string') {
      item.textField.text = text;
    } else if (text && typeof text === 'object') {
      item.textField.style = Object.assign(item.textField.style, text.style);
      item.textField.text = text.text;
    }

    if (item.textField) {
      item.textField.fitSize(false, true);
    }
  }

  getLocalizedText(key, params) {
    if (!this.localization && !this.fallbackLocalization) {
      return;
    }
    let text = '';
    let textData = this.parseTranslationKey(key, this.localization) || this.parseTranslationKey(key, this.fallbackLocalization);
    if (!textData) {
      textData = key;
      console.warn(`Not found localization text by key: ${key}`);
    }
    switch (typeof textData) {
      case 'object':
        if (Array.isArray(textData)) {
          text = textData.join('');
        } else {
          text = textData;
        }
        break;
      case 'string':
        text = textData;
        break;
      case 'number':
        text = textData.toString();
        break;
    }

    if (params) {
      params.forEach((param, index) => {
        text = text.replace(`{${index}}`, param);
      });
    }

    return text;
  }

  parseTranslationKey(string, json) {
    if (!string || !json) return;
    if (string.indexOf('.') === -1) return json[string];
    const array = string.split('.');
    let result = json;
    for (let i = 0; i < array.length; i++) {
      if (!result.hasOwnProperty(array[i])) {
        result = null;
        break;
      }
      result = result[array[i]];
    }
    return result;
  }

  getTextFieldIndex(textField) {
    return this.textFields.findIndex((element) => {
      return element.textField === textField;
    });
  }

  removeTextField(textField) {
    let index = this.getTextFieldIndex(textField);
    if (index !== -1) {
      this.textFields.splice(index, 1);
    }
  }

  addLocalizationChangedCallback(callback, context) {
    this.localizationChangedCallbacks.push({ function: callback, context: context });
  }

  removeLocalizationChangedCallback(callback) {
    this.localizationChangedCallbacks = this.localizationChangedCallbacks.filter((item) => item.function !== callback);
  }

  async _tryLoadGameLang(lang) {
    if (!this.gameBundle) {
      console.warn('Localization manager: \nPlease specify game bundle');
      return false;
    }

    let result = true;
    let resp = await fetch(this.requestUrl.replace('${bundle}', this.gameBundle).replace('${lang}', lang))
      .catch(async () => await fetch(this.fallbackRequestUrl.replace('${bundle}', this.gameBundle).replace('${lang}', lang))).catch(() => result = false);

    let data = {};
    if (resp) {
      data = await resp.json()
        .catch(() => result = false);
    }

    if (result) {
      if (this.localizations[lang] && this.localizations[lang][this.wrapperLocalizationsKey]) {
        data = {
          ...data,
          [this.wrapperLocalizationsKey]: this.localizations[lang][this.wrapperLocalizationsKey]
        };
      }

      this.localizations[lang] = data;
    }

    return result;
  }

  async _tryLoadWrapperLang(lang) {
    let result = true;
    let resp = await fetch(this.requestUrl.replace('${bundle}', this.wrapperTranslationsServerKey).replace('${lang}', lang))
      .catch(async () => await fetch(this.fallbackRequestUrl.replace('${bundle}', this.wrapperTranslationsServerKey).replace('${lang}', lang))).catch(() => result = false);

    let data = {};
    if (resp) {
      data = await resp.json()
        .catch(() => result = false);
    }

    if (result) {
      if (!this.localizations[lang]) this.localizations[lang] = {};
      this.localizations[lang][this.wrapperLocalizationsKey] = data;
    }

    return result;
  }

  async tryLoadTournamentLocalizations(tournamentId, lang = this.currentLanguage, useFallbackLanguage = false) {
    const tournamentKey = OP_WRAPPER_TOURNAMENTS_LOCALIZATION_PREFIX.concat(tournamentId);
    if (this.localizations[lang] && this.localizations[lang][tournamentKey]) return;

    let result = true;
    let resp = await fetch(this.requestUrl.replace('${bundle}', tournamentKey).replace('${lang}', lang))
      .catch(async () => await fetch(this.fallbackRequestUrl.replace('${bundle}', tournamentKey).replace('${lang}', lang))).catch(() => result = false);

    let data = {};
    if (resp) {
      data = await resp.json().catch(async () => result = false);
      if (!result && !useFallbackLanguage) return await this.tryLoadTournamentLocalizations(tournamentId, this.fallbackLanguage, true);
    }

    if (result) {
      if (!this.localizations[lang]) this.localizations[lang] = {};
      this.localizations[lang][tournamentKey] = data;
      if (lang === this.fallbackLanguage) {
        if (!this.fallbackLocalization) this.fallbackLocalization = {};
        this.fallbackLocalization[tournamentKey] = data;
      }

      this.onTournamentLocalizationReceived.call({
        localizationId: tournamentId,
        language: lang,
        currentLanguage: this.currentLanguage,
        fallbackLanguage: this.fallbackLanguage,
      });
    }

    return result;
  }
};
