import FlashLib from 'flashlib';
import Constants from '../../constants';
import serverClientSymbols from '../symbol/serverClientSymbols';
import { store, storeObserver } from '../../index';
import selectors from '../../redux/game/selectors';
import { eGameState } from '../../redux/game/reducer';
import ControllerSlotNormalBehaviour from './behaviour/controllerSlotNormalBehaviour';
import ControllerSlotStartFreespinsBehaviour from './behaviour/controllerSlotStartFreespinsBehaviour';
import ControllerSlotFreespinsBehaviour from './behaviour/controllerSlotFreespinsBehaviour';
import ControllerSlotLastFreespinBehaviour from './behaviour/controllerSlotLastFreespinBehaviour';
import { sleep } from '../../utils/sleep';
import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import constants from '../../constants';

export default class ControllerSlot extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.reels = [];
    const mask = this.getChildByName('maskArea');
    const reels = this.getChildByName('reelsArea');
    reels.mask = mask;

    for (let i = 0; i < Constants.REELS_COUNT; i++) {
      const item = reels.getChildByName(`reel_${i}`);
      item.visible = !(i > 4);
      item.setupAnimations(0);
      this.reels.push(item);
    }

    this.animationTimeouts = [];
    this.lineIndex = 0;
    this.forceStopped = false;
    this.isScatterDrop = false;

    this.eFrameAnimationType = {
      FRAME_IDLE: 'frame_scatter_idle',
      FRAME_START: 'frame_scatter_start',
    };

    storeObserver.addListener(selectors.getGameState, this.onGameStateChanged);

    this.goldenFrame = this.getChildByName('golden_frame');

    this.frameAnimation = animationsLoader.createAnimation(eAnimationType.EAT_SYMBOLS);
    // this.frameAnimation.x = this.goldenFrame.displayData.width / 2;
    // this.frameAnimation.y = this.goldenFrame.displayData.height / 2;
    this.goldenFrame.addChild(this.frameAnimation);
    this.frameAnimation.visible = false;
    this.frameAnimation.state.addListener({
      complete: (data) => {
        this.frameAnimation.visible = false;
      }
    });

    this.freespinsEnabled = selectors.getFreespinsEnabled(store.getState());
    if (this.freespinsEnabled) {
    } else {
      this.goldenFrame.visible = false;
    }

    this._behaviour = new ControllerSlotNormalBehaviour(this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize = (data) => {
    if (data.isMobile && data.isLandscape) {
      this.anchorDefaultX();
      this.y = this.displayData.y;
      this.scale.set(this.displayData.scaleX);
    } else if (data.isMobile && data.isPortrait) {
      const slotCenterX = this.goldenFrame.displayData.x;
      this.pivot.x = slotCenterX;
      this.x = data.gameHeight / 2;
      this.y = this.displayData.y + 120;
      this.scale.set(0.67);
    }
  }

  onGameStateChanged = (state) => {
    const bonusGameShowed = selectors.getBonusGameShowed(store.getState());
    if (bonusGameShowed) return;
    if (state === eGameState.EGS_WAITING_FOR_RESPONSE) {
      this.spin();
    } else if (state === eGameState.EGS_CAN_STOP) {
      this.stop();
    } else if (state === eGameState.EGS_STOP_SPIN && !store.getState().game.autospin) {
      this.forceStop();
    }
  };

  spin() {
    this.lineIndex = 0;
    this.isScatterDrop = false;
    this.forceStopped = false;
    this.clearTimeouts();
    this.freespinsEnabled = selectors.getFreespinsEnabled(store.getState());
    const reelsCount = constants.REELS_COUNT;

    for (let i = 0; i < reelsCount; i++) {
      this.reels[i].spin();
    }
  }

  async stop() {
    this.clearTimeouts();
    const line = selectors.getStopReels(store.getState());
    const delay = 1000;
    this.reelStopTimeout = setTimeout(() => {
      for (let i = 0; i < Constants.REELS_COUNT; i++) {
        const symbolId = line[i];
        const symbolType = serverClientSymbols[symbolId];
        const callback = i === line.length - 1 ? this.onStopAnimationCompleted : () => {};

        this.reels[i].stop(symbolType, symbolId, callback);
      }
    }, delay);
  }

  forceStop() {
    // this.clearTimeouts();
    clearTimeout(this.reelStopTimeout);
    this.forceStopped = true;
    this.lineIndex = 0;

    const line = selectors.getStopReels(store.getState());
    for (let i = 0; i < line.length; i++) {
      const symbolType = serverClientSymbols[line[i]];
      const callback = i === line.length - 1 ? this.onStopAnimationCompleted : () => {};

      this.reels[i].forceStop(symbolType, callback);
    }

    this.reels.forEach(reel => this.disableAnticipator(reel));
  }

  disableAnticipator(reel) {
    reel.showAnticipator(false);
  }

  onStopAnimationCompleted = async (reel) => {

    const state = store.getState().game;

    this.reels.forEach(reel => this.disableAnticipator(reel));
    if (state.freespinsEnabled && state.freespinsCount === state.freeSpinsMax) {
      this._behaviour = new ControllerSlotStartFreespinsBehaviour(this);
    } else if (state.isLastFreespin && state.freespinsCount === 0) {
      this._behaviour = new ControllerSlotLastFreespinBehaviour(this);
    } else if (!state.isLastFreespin && state.freespinsCount !== 0) {
      this._behaviour = new ControllerSlotFreespinsBehaviour(this);
    } else {
      this._behaviour = new ControllerSlotNormalBehaviour(this);
    }

    await sleep(200);
    this._behaviour.endSpin();
  };

  async startWinAnimation(winLines, firstRun) {
    let delay = 0;
    let isWin = false;
    winLines.forEach(line => {
      isWin = true;
      const timeout = setTimeout(() => {
        line.forEach(index => this.reels[index].playSymbolAnimation());
      }, delay);
      delay += 2000;
      this.animationTimeouts.push(timeout);

    });

    if (!this.freespinsEnabled) {
      const timeout = setTimeout(() => {
        this.startWinAnimation(winLines, false);
      }, delay);
      this.animationTimeouts.push(timeout);
    }

    return { delay, isWin };
  }

  clearTimeouts() {
    for (let i in this.animationTimeouts) {
      clearTimeout(this.animationTimeouts[i]);
      delete this.animationTimeouts[i];
    }
    this.animationTimeouts.length = 0;
  }

  setIdleFrameAnimation = () => {
    this.frameAnimation.visible = true;
    this.goldenFrame.visible = true;
    this.frameAnimation.state.setAnimation(0, this.eFrameAnimationType.FRAME_IDLE, false);
  }
  setStartFrameAnimation = () => {
    this.frameAnimation.visible = true;
    this.goldenFrame.visible = true;
    this.frameAnimation.state.setAnimation(0, this.eFrameAnimationType.FRAME_START, false);
    this.frameAnimation.state.addAnimation(0, this.eFrameAnimationType.FRAME_IDLE, false);

  }
  closeFrame = () => {
    this.frameAnimation.visible = false;
    this.goldenFrame.visible = false;
  }
}
