export const formatCurrency = (value, decimals, hideFractionIfPossible = true) => {
  let splitValue = value.toFixed(decimals).split('.');
  let fraction = '';

  if (decimals === 2) {
    if (!hideFractionIfPossible || parseFloat(splitValue[1]) !== 0) {
      fraction = '.' + splitValue[1];
    }
  } else if (decimals > 0 && parseFloat(splitValue[1]) !== 0) {
    fraction = '.' + splitValue[1].replace(/0*$/, '');
  }
  let mainValue = splitValue[0];
  let result = [];
  for (let i = mainValue.length - 1; i >= 0; i--) {
    if (i !== mainValue.length - 1 && (mainValue.length - 1 - i) % 3 === 0) result.unshift(' ');
    result.unshift(mainValue[i]);
  }
  return result.join('') + fraction;
};
