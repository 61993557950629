import UiAdapter from './uiAdapter';
import { eSoundType } from '../sounds/controllerSounds';

const uiColors = {
  overlay: {
    default: 'rgba(51, 0, 18, 0.9)',
  },
  primary: {
    default: '#FF0F0099',
    hover: '#FF847C99',
    pressed: '#9A0000CC',
    disabled: '#FF675E99',
  },
  secondary: {
    default: '#FF6B00CC',
    hover: '#FF9E45CC',
    pressed: '#CB4900CC',
  },
  toggled: {
    default: '#F00073',
  },
  cancel: {
    default: '#F11C5C',
    hover: '#FF2C6B',
    pressed: '#E00043',
  },
  panel: {
    default: '#860B0066',
  }
};

const config = {
  H: {
    colors: uiColors,
    offsets: {
      right_panel: { top: 0, left: -11 },
      tournaments_panel: { left: -1430 }
    }
  },
  V: {
    colors: uiColors,
    offsets: {
      top: -30,
      left: 0,
    }
  }
};

const initUi = () => {
  const ControllerUi = window.OPWrapperService.initUi(config);
  new UiAdapter(ControllerUi).init({
    clickSound: { soundName: eSoundType.EST_CLICK, volume: 0.7 },
  });
};

export {
  initUi,
};
