import ModalBase from '../base/modalBase';
import eModalType from './eModalType';
import { yellowStrokeGradient} from '../../fontStyles';
import { TweenMax } from 'gsap';

export default class ModalWonBonusGame extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;
    this.type = eModalType.EMT_WON_BONUS_GAME;

    this.init();
  }

  init() {
    if (PIXI.utils.isMobile.any) {
      this.background = this.getChildByName('bg');
      this.portretBackground = this.getChildByName('bg_portret');
      this.portretBackground.children[1].alpha = 0.8;
    }
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );

    this.setTextStyle();
    this.initListener();
  }

  onResize = (data) => {
    if (data.isMobile && data.isLandscape) {
      this.background.visible = true;
      this.portretBackground.visible = false;
    } else if (data.isMobile && data.isPortrait) {
      this.background.visible = false;
      this.portretBackground.visible = true;
    }
  }

  setTextStyle = () => {
    const text = this.getChildByName('popup_won_bonus_game');
    text.style = { ...text.style, ...yellowStrokeGradient };
  }

  destroy() {
    super.destroy();
    this.off('pointertap', this.onClick);
  }

  onClick = () => {
    this.hide();
  }

  show() {
    this.alpha = 0;
    TweenMax.to(this, 0.5, { alpha: 1 });
    setTimeout(this.hide.bind(this), 3000);
  }

  onShowed = () => {
    this.show();
  };
}
