import { handleActions } from 'redux-actions';

import actions from './actions';
import serverClientSymbols from '../../controllers/symbol/serverClientSymbols';
import { eScreenMode } from '../../enums';
import { getUrlParam } from '../../libs/op-wrapper-service/utils/url';

export const eGameState = {
  EGS_READY_TO_PLAY: 'ready_to_play',
  EGS_WAITING_FOR_RESPONSE: 'waiting_for_response',
  EGS_CAN_STOP: 'can_stop',
  EGS_SHOW_WIN: 'show_win',
  EGS_UPDATE_EXP: 'update_exp',
  EGS_UPDATE_HEALTH: 'update_health',
  EGS_START_AUTOSPIN: 'start_autospin',
  EGS_STOP_AUTOSPIN: 'stop_autospin',
  EGS_STOP_SPIN: 'stop_spin',
  EGS_FREESPIN_MODE: 'freespin_mode',
  EGS_BUY_BONUS_GAME: 'buy_bonus_game'
};

const initialState = {
  gameScreen: eScreenMode.START,
  betAmount: 1,
  currency: 'RUB',
  round_hash: '',
  availableSymbols: [],
  winAmount: 0,
  lastWinAmount: 0,
  bets: [],
  betsAvailable: [],
  freeBetsAvailable: [],
  autospin: false,
  // user state
  balance: 0.5,
  gameState: eGameState.EGS_READY_TO_PLAY,
  betMin: 1,
  betMax: 100,
  freespinsEnabled: false,
  freespinsMode: false,
  freespinsCount: 0,
  freeSpinsMax: 0,
  freespinsSymbols: [],
  bonusGameAvailable: false,
  bonusGameShowed: false,
  bonusGameTotalWin: 0,
  bonusGameField: [1, 1, 1, 2, 2, 2, 3, 3, 3, 4, 4, 4, 5, 5, 5, 10],
  enemyHeals: 50,
  myHeals: 50,
  enemyMaxHeals: 60,
  exp: 0,
  progressToNextLevel: 0,
  level: 1,
  levelMultiplier: 1,
  currentEnemy: 0,
  realCurrentEnemy: 0,
  multiplyersList: [],
  targetMultiplier: 0,
  currencyInfo: {
    symbol: '₽',
    decimal: 2,
    currency: 'rub',
    balance: 0,
  }
};

const setData = (state, { payload }) => {
  convertToClientSymbols(payload.available_icons);

  const paytable = {};
  payload.slot_machine.paytable.forEach((p, index) => {
    paytable[serverClientSymbols[index + 2]] = p;
  });
  const betsAvailable = payload.bets_available;
  // pid : 217 PMBelarus
  const defaultBetIndex = 0;
  const bonusPrices = payload.bonusGamePrice;
  let currentBetIndex = payload.bet_amount ? betsAvailable.indexOf(payload.bet_amount) : defaultBetIndex;
  let betAmount = payload.bet_amount || betsAvailable[defaultBetIndex];
  let bets = betsAvailable;

  //freeBets
  let freeBetsAvailable = [];
  if (payload.freebets) {
    freeBetsAvailable = payload.freebets.bet_levels;
    const freeBetsIsActive = payload.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_ACTIVE;

    if (freeBetsIsActive) {
      currentBetIndex = payload.freebets.bet_level;
      betAmount = freeBetsAvailable[currentBetIndex];
      bets = freeBetsAvailable;
    }
  }

  return {
    ...state,
    balance: payload.balance,
    availableSymbols: payload.available_icons,
    freeBetsAvailable,
    betsAvailable,
    bets,
    betMin: bets[0],
    betMax: bets[bets.length - 1],
    currentBetIndex,
    betAmount,
    paytable,
    bonusPrices,
    enemyHeals: payload.progressGame?.currentEnemyHealth || state.enemyHeals,
    myHeals: payload.progressGame?.playerHealth || 50,
    enemyMaxHeals: payload.progressGame?.currentEnemyHealthTotal || state.enemyMaxHeals,
    exp: payload.progressGame?.exp || state.exp,
    progressToNextLevel: payload.progressGame?.progressPercentage || state.progressToNextLevel,
    level: payload.progressGame?.level || state.level,
    levelMultiplier: payload.progressGame?.levelMultiplier || state.levelMultiplier,
    currentEnemy: payload.progressGame?.currentEnemy - 1 || state.currentEnemy,
    isChangedCurrentEnemy: false,
    realCurrentEnemy: payload.progressGame?.currentEnemy - 1 || state.currentEnemy,
    bonusGameAvailable: payload.isBonusGame,
    bonusGameShowed: payload.isBonusGame,
    bonusGameTotalWin: payload.bonusGameTotal || 0,
    bonusGameField: payload.bonusGameSource || state.bonusGameField,
    freespinsEnabled: payload.isFreeSpins,
    freespinsCount: payload.freeSpinsCount,
    freeSpinsMax: payload.isFreeSpins && 5,
    totalWinFS: payload.freeSpinWinTotal,
    multiplyersList: payload.bonusGameList,
    leaderboardInfo: payload.leaderboard,
    myPosition: payload.leaderboard.my.pos,
    currencyInfo: {
      symbol: payload.currency,
      //todo: remove decimal in next release
      decimal: payload.decimal || payload.decimals,
      currency: payload.currency,
      balance: payload.balance,
    }
  };
};

const setNewGame = (state, { payload }) => {
  let winLines = [];
  for (const lineInfo of payload.spin.result) {
    winLines.push(lineInfo.ind);
  }

  let bets = state.betsAvailable;

  let betAmount = payload.hasOwnProperty('bet_amount')
    ? payload.bet_amount
    : payload.hasOwnProperty('bet_index')
      ? state.betsAvailable[payload.bet_index]
      : state.betAmount;

  let currentBetIndex = payload.hasOwnProperty('bet_index')
    ? payload.bet_index
    : payload.hasOwnProperty('bet_amount')
      ? state.betsAvailable.indexOf(payload.bet_amount)
      : state.currentBetIndex;

  const winAmount = payload.total_win || 0;

  //freeBets
  let freeBetsAvailable = [];
  let freeBetsIsActive;
  let isLastFreeBet;

  if (payload.freebets) {
    freeBetsAvailable = payload.freebets.bet_levels;
    freeBetsIsActive = payload.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_ACTIVE;
    isLastFreeBet = payload.freebets && payload.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_PLAYED;

    if (freeBetsIsActive) {
      currentBetIndex = payload.freebets.bet_level;
      betAmount = freeBetsAvailable[currentBetIndex];
      bets = freeBetsAvailable;
    }
  }

  window.OPWrapperService.ControllerStatistic.win = winAmount * ((state.bonusGameShowed || state.freespinsEnabled) && payload.progressGame?.levelMultiplier || 1);
  if (freeBetsIsActive || isLastFreeBet) {
    window.OPWrapperService.ControllerStatistic.increaseFreeBetsCount();
  } else {
    window.OPWrapperService.ControllerStatistic.increaseSpinCount();
  }

  const currentEnemy = payload.progressGame.currentEnemy - 1;
  return {
    ...state,
    freeBetsAvailable,
    bets,
    betAmount,
    currentBetIndex,
    winAmount,
    lastWinAmount: state.winAmount || state.lastWinAmount,
    gameState: eGameState.EGS_CAN_STOP,
    stopReels: payload.spin.reels,
    winLine: winLines,
    balance: payload.balance || 0,
    enemyHeals: payload.progressGame.currentEnemyHealth,
    myHeals: payload.progressGame?.playerHealth || 0,
    enemyMaxHeals: payload.progressGame?.currentEnemyHealthTotal || state.enemyMaxHeals,
    exp: payload.progressGame.exp,
    differenceExp: payload.progressGame.exp - state.exp || 0,
    currentRound: payload.progressGame.currentRound,
    progressToNextLevel: payload.progressGame?.progressPercentage || state.progressToNextLevel,
    level: payload.progressGame.level,
    levelMultiplier: payload.progressGame.levelMultiplier,
    currentEnemy,
    enemyChanged: state.currentEnemy !== currentEnemy,
    isChangedCurrentEnemy: payload.progressGame?.currentEnemy - 1 !== state.currentEnemy,
    bonusGameAvailable: payload.isBonusGame,
    bonusGameTotalWin: payload.bonusGameTotal,
    freespinsEnabled: payload.isFreeSpins,
    freespinsCount: payload.freeSpinsCount,
    freeSpinsMax: payload.isFreeSpins && 5,
    totalWinFS: payload.freeSpinWinTotal,
    multiplyersList: payload.bonusGameList,
    bonusGameField: payload.bonusGameSource || state.bonusGameField,
    targetMultiplier: payload.bonusGameLastMult,
    targetMultiplierIndex: payload.bonusGameSelectedIndex,
    isLastFreespin: payload.isLastFreeSpin,
    myPosition: payload.leaderboardPosition?.pos || state.myPosition,
    bonusPrices: payload.bonusGamePrice || state.bonusPrices,
  };
};

const setBonusGame = (state, { payload }) => {
  return {
    ...state,
    balance: payload.balance || 0,
    bonusGameAvailable: payload.isBonusGame,
    freespinsEnabled: false,
    multiplyersList: payload.bonusGameList,
    bonusGameTotal: payload.bonusGameTotal,
    bonusGameField: payload.bonusGameSource || state.bonusGameField,
    bonusGameTotalWin: 0
  };
};

const setLeaderboard = (state, { payload }) => {
  return {
    ...state,
    leaderboardInfo: payload.leaderboard,
    myPosition: payload.leaderboard.my.pos,
  };
};

const tryShowBonusGame = (state) => {
  return {
    ...state,
    bonusGameShowed: state.bonusGameAvailable,
  };
};

const closeBonusGame = (state) => {
  return {
    ...state,
    bonusGameShowed: false
  };
};

const incrementBet = (state) => {
  const step = state.currentBetIndex + 1;
  if (step >= state.bets.length) {
    return state;
  }
  let newAmount = state.bets[step];

  return {
    ...state,
    betAmount: newAmount,
    currentBetIndex: step,
  };
};

const decrementBet = (state) => {
  const step = state.currentBetIndex - 1;
  if (step < 0) {
    return state;
  }
  let newAmount = state.bets[step];

  return {
    ...state,
    betAmount: newAmount,
    currentBetIndex: step,
  };
};

const setMinBet = (state) => {
  return {
    ...state,
    betAmount: state.betMin,
    currentBetIndex: 0,
  };
};

const setMaxBet = (state) => {
  const bets = state.bets;
  const index = bets.length - 1;
  const bet = bets[index] ? bets[index] : state.bets[0];

  return {
    ...state,
    betAmount: bet,
    currentBetIndex: index,
  };
};

const setBet = (state, index) => {
  if (!state.bets[index.payload]) return;
  return {
    ...state,
    currentBetIndex: index.payload,
    betAmount: state.bets[index.payload]
  };
};

const setCurrencyInfo = (state, { payload }) => {
  //todo: ask Vova to send balance with floating point
  return ({
    ...state,
    currency: payload.currency.toUpperCase(),
    balance: payload.balance / Math.pow(10, payload.decimal),
    currencyInfo: payload
  });
};

const convertToClientSymbols = (array) => {
  for (let i = 0; i < array.length; i++) {
    const reel = array[i];
    for (let j = 0; j < reel.length; j++) {
      reel[j] = serverClientSymbols[reel[j]];
    }
  }
};

export default handleActions(
  {
    [actions.setData]: setData,
    [actions.setNewGame]: setNewGame,
    [actions.incrementBet]: incrementBet,
    [actions.decrementBet]: decrementBet,
    [actions.setMinBet]: setMinBet,
    [actions.setMaxBet]: setMaxBet,
    [actions.setBet]: setBet,
    [actions.setCurrencyInfo]: setCurrencyInfo,
    [actions.tryShowBonusGame]: tryShowBonusGame,
    [actions.closeBonusGame]: closeBonusGame,
    [actions.setBonusGame]: setBonusGame,
    [actions.setLeaderboard]: setLeaderboard,
    [actions.setGameState]: (state, { payload }) => {
      console.log(`State: ${payload}`);
      return { ...state, gameState: payload };
    },
    [actions.setBalance]: (state, { payload }) => ({ ...state, balance: payload }),
    [actions.startAutospin]: (state) => ({ ...state, autospin: true }),
    [actions.updateRealCurrentEnemy]: (state) => ({ ...state, realCurrentEnemy: state.currentEnemy }),
    [actions.stopAutospin]: (state) => ({ ...state, autospin: false }),
    [actions.setFreespinMode]: (state, { payload }) => ({ ...state, freespinsMode: payload }),
    [actions.setScreenMode]: (state, { payload }) => ({ ...state, gameScreen: payload })
  },
  initialState,
);
