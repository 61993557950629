const mainFunction = (timeProgressFunction ,startValue, endValue, executionFunction, duration, callBack = () => {}) => {
  let timeStart = Date.now();
  const differenceXPProgress = endValue - startValue;
  let needStop;
  let timeNow;

  const update = () => {
    const differenceTimeBetweenFrame = Date.now() - timeNow;
    if (differenceTimeBetweenFrame > 100) timeStart += differenceTimeBetweenFrame;
    timeNow = Date.now();
    const differenceTime = timeNow - timeStart;
    if ( differenceTime <= duration && !needStop) {
      const timeProgress = timeProgressFunction(differenceTime);
      const currentXPProgress = startValue + differenceXPProgress * timeProgress;
      needStop = executionFunction(currentXPProgress);
      requestAnimationFrame(update);
    } else {
      executionFunction(endValue);
      callBack();
      return;
    }
  };
  requestAnimationFrame(update);
};
export const exponentiationEaseOut = (startValue, endValue, executionFunction, duration, callBack = () => {}, pow = 5) => {
  const timeProgressFunction = (difference) => 1 - Math.pow(1 - difference / duration, pow);
  mainFunction(timeProgressFunction, startValue, endValue, executionFunction, duration, callBack);
};

export const exponentiation = (startValue, endValue, executionFunction, duration, callBack = () => {}, pow = 5) => {
  const timeProgressFunction = (difference) => Math.pow( difference / duration, pow);
  mainFunction(timeProgressFunction, startValue, endValue, executionFunction, duration, callBack);
};

export const linear = (startValue, endValue, executionFunction, duration, callBack = () => {}) => {
  const timeProgressFunction = (difference) => difference / duration;
  mainFunction(timeProgressFunction, startValue, endValue, executionFunction, duration, callBack);
};

