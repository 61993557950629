import ControllerSounds, { eSoundType } from './sounds/controllerSounds';
import { formatCurrency } from '../utils/currency';

export default class WinAmountCounter {
  constructor(element, style) {
    const label = element.getChildByName('label');
    label.visible = false;

    this.label = new PIXI.BitmapText('0', style);
    this.label.anchor.set(style.anchor.x, style.anchor.y);
    this.label.x = label.x + (label.width / 2);
    this.label.y = label.y + (label.height / 2);
    this.label.visible = false;
    element.addChild(this.label);

    this.onStop = () => {};
  }

  animate(value, decimals = 2, durationCount = 1, durationEnd = 2) {
    this.needStop = false;
    this.durationCount = durationCount;
    this.durationEnd = durationEnd * 1000;
    this.label.visible = true;
    this.decimals = decimals;
    const arrForDecimals = formatCurrency(value, this.decimals).split('.');
    this.decimalsForAnimation = arrForDecimals[1] ? arrForDecimals[1].length : 0;
    this.startValue = 0;
    this.endValue = value;
    this.startTime = Date.now();
    this.difference = this.endValue - this.startValue;
    this.duration = durationCount * 1000;
    this.animateValueChanged();
  }

  show = (value, decimals = 2) => {
    this.label.visible = true;
    this.label.text = formatCurrency(value, decimals);

    this.timeout = setTimeout(this.stop, this.durationEnd);
  }

  stop = () => {
    this.needStop = true;
    if (this.timeout) clearTimeout(this.timeout);
    if (this.soundTimeout) clearTimeout(this.soundTimeout);
    this.label.text = '0';
    this.label.visible = false;
    this.onStop();
  };

  onAnimationComplete = () => {
    this.timeout = setTimeout(this.stop, this.durationEnd);
  };

  playSound(delay = 0, index = 0) {
    this.soundTimeout = setTimeout(() => {
      ControllerSounds.playSound(eSoundType.EST_COINS);
      index++;
      if (index < 5) {
        this.playSound(delay + 150, index);
      }
    }, delay);
  }

  animateValueChanged = () => {
    const differenceTime = Date.now() - this.startTime;
    if (differenceTime < this.duration && !this.needStop) {
      const progress = differenceTime / this.duration;
      const value = this.startValue + this.difference * progress;
      this.label.text = value.toFixed(this.decimalsForAnimation);
      requestAnimationFrame(this.animateValueChanged);

    } else {
      this.label.text = formatCurrency(this.endValue, this.decimals);
      this.onAnimationComplete();
    }
  };
}

