import eSymbolType from './eSymbolType';

export default {
  [eSymbolType.EST_LOW_2]: 'a',
  [eSymbolType.EST_LOW_0]: 'q',
  [eSymbolType.EST_LOW_1]: 'j',
  [eSymbolType.EST_LOW_3]: 'k',
  [eSymbolType.EST_HIGH_1]: 'grusha',
  [eSymbolType.EST_HIGH_2]: 'mouthguard',
  [eSymbolType.EST_HIGH_0]: 'boot',
  [eSymbolType.EST_BONUS]: 'helmet_bonus_game_idle',
  [`${eSymbolType.EST_BONUS}_START`]: 'helmet_bonus_game_start',
  [eSymbolType.EST_WILD]: 'wild',
  [`${eSymbolType.EST_WILD}_START`]: 'wild_start',
  [eSymbolType.EST_SCATTER]: 'glove_scatter_idle',
  [`${eSymbolType.EST_SCATTER}_START`]: 'glove_scatter_start',
};
