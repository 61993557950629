import { TweenMax } from 'gsap';
import { store } from '../../index';
import { storeObserver } from '../../index';
import actionsModal from '../../redux/modals/actions';
import eModalType from '../popups/eModalType';
import selectors from '../../redux/game/selectors';
import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import { eGameState } from '../../redux/game/reducer';
import FlashLib from 'flashlib';

export default class ControllerBonusButton extends FlashLib.MovieClip {
  constructor(data, $displayItemData) {
    super(data, $displayItemData);
    this.visible = false;

    if (!OPWrapperService.config.disableBuyBonus) {
      this._init();
    }
  }

  _init() {
    this.button = this.getChildByName('btn');
    this.button.onClick = this.onClickButton;
    this.initAnimation();
    this.show();
    // this.bonusGameEnabled = storeObserver.addListenerAndGetValue(selectors.getBonusGameShowed, this.onBonusGameShowed);
    const gameState = storeObserver.addListenerAndGetValue(selectors.getGameState, this.onGameStateChanged);
    // this.onBonusGameShowed(this.bonusGameEnabled);
    this.onGameStateChanged(gameState);

  }

  onResize = () => {
    // const width = Math.min(window.innerWidth, window.outerWidth);
    // const height = Math.min(window.innerHeight, window.outerHeight);
    // const isHorizontalOrientation = (width - height) > 0;
    // const widthGame = constants.GAME_WIDTH;
    //
    // if (PIXI.utils.isMobile.any && isHorizontalOrientation) {
    //   this.y = this.displayData.y;
    //   this.x = this.displayData.x;
    //   this.scale.set(1);
    // } else if (PIXI.utils.isMobile.any) {
    //   this.y = 950;
    //   this.x = widthGame / 2 - 240;
    //   this.scale.set(0.6);
    // }
  }

  onBonusGameShowed = (value) => {
    this.bonusGameEnabled = value;
    this.bonusGameEnabled ? this.hide() : this.show();
  }

  onGameStateChanged = (gameState) => {
    const state = store.getState().game;
    this.setButtonsEnable(
      gameState === eGameState.EGS_READY_TO_PLAY
      && !state.autospin
      && !state.freespinsEnabled
      && !window.OPWrapperService.freeBetsController.isActive
      && !window.OPWrapperService.freeBetsController.isFirstFreeBet
    );
  };

  initAnimation = () => {
    this.animation = animationsLoader.createAnimation(eAnimationType.EAT_BONUS_BUTTON);
    this.animation.x = this.displayData.width / 2;
    this.animation.y = this.displayData.height / 2;
    this.animation.visible = false;

    this.addChild(this.animation);
  }

  setButtonsEnable = (value) => {
    this.button.enabled = value;
    this.button.enabled = value;
  };

  show = () => {
    if (this.visible) return;
    this.timeout = setInterval(this.trySetAnimation, 20000);
    this.visible = true;
    TweenMax.to(this, 2, { alpha: 1 });
  }
  hide = () => {
    if (!this.visible) return;
    clearTimeout(this.timeout);
    TweenMax.to(this, 0.5, { alpha: 0, onComplete: () => this.visible = false });
  }

  trySetAnimation = () => {
    if (this.button.interactive){
      this.animation.visible = true;
      this.animation.state.setAnimation(0, 'animation', false);
    }
  }

  destroy() {
    clearTimeout(this.timeout);
    super.destroy();
  }

  onClickButton() {
    store.dispatch(actionsModal.showModal({ type: eModalType.EMT_BONUS_PURCHASE }));
  }
}
