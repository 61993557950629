import ModalBase from '../base/modalBase';
import eModalType from './eModalType';
import {
  winAmountCounterStyle,
  yellowStrokeGradient,
} from '../../fontStyles';
import { store } from '../../index';
import WinAmountCounter from '../winAmountCounter';
import selectors from '../../redux/game/selectors';
import actions from '../../redux/game/actions';
import controllerSounds from '../sounds/controllerSounds';

export default class ModalTotalWin extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;
    this.type = eModalType.EMT_TOTAL_WIN;

    // setTimeout(this.onModalDataChanged.bind(this, { visible: true }), 2000);

    this.init();
  }

  init() {
    this.container = this.getChildByName('container');
    this.totalWinCounterContainer = this.container.getChildByName('totalWinCounter');
    this.totalWinAmountCounter = new WinAmountCounter(this.totalWinCounterContainer, { ...winAmountCounterStyle, fontSize: 120 });
    this.initListener();
    this.setTextStyle();
    // this.on('click', this.onClick);
    // document.addEventListener('keydown', event => this.onKeyDown(event), false);
  }

  destroy() {
    super.destroy();
    this.off('click', this.onClick);
  }

  setAmounts(value) {
    const levelMultiplier = selectors.getLevelMultiplier(store.getState());

    const currencyInfo = selectors.getCurrencyInfo(store.getState());
    const decimals = Math.min(value.getPrecision(), currencyInfo.decimal);
    this.totalWinAmountCounter.animate(value * levelMultiplier, decimals, 3);
    this.totalWinAmountCounter.onStop = () => {
      this.fade();
      store.dispatch(actions.closeBonusGame());
    };
  }

  showBlackout() {
    const blackout = this.getChildByName('blackout');
    if (!blackout) return;
    blackout.show(true, 0.3);
  }

  setTextStyle() {
    const youWinText = this.container.getChildByName('total_win_title');
    const state = store.getState();
    const freespinsEnabled = selectors.getFreespinsEnabled(state);
    const isLastFreespin = selectors.getIsLastFreespin(state);
    const LocalizationManager = window.OPWrapperService.localizations;
    youWinText.text = freespinsEnabled || isLastFreespin
      ? LocalizationManager.getLocalizedText('freespins_total_win_title')
      : LocalizationManager.getLocalizedText('bonus_game_total_win_title');
    youWinText.style = { ...youWinText.style, ...yellowStrokeGradient };
    youWinText.fitSize(true, true);
  }

  show(amount) {
    this.showBlackout();
    this.setTextStyle();
    this.setAmounts(amount);
    controllerSounds.playTotalWin();

  }

  onShowed = () => {
    const state = store.getState();
    const bonusGameShowed = selectors.getBonusGameShowed(state);
    const totalWin = bonusGameShowed ? state.game.bonusGameTotalWin : state.game.totalWinFS ;
    this.show(totalWin);
  };

  onKeyDown(event) {
    if (event.keyCode === 32 && this.visible) { //32 - Space
      this.totalWinAmountCounter.stop();
      this.hide();
    }
  }

  onClick() {
    this.totalWinAmountCounter.stop();
    this.hide();
  }

  hide() {
    controllerSounds.stopTotalWin();
    super.hide();
  }

}
