import FlashLib from 'flashlib';
import { whiteBeigeGradient } from '../../../fontStyles';
import { store, storeObserver } from '../../../index';
import modalSelectors from '../../../redux/modals/selectors';
import eModalType from '../eModalType';
import { TweenMax } from 'gsap';
import ePopupAnimation from '../ePopupAnimation';
import animationsLoader from '../../animations/animationsLoader';
import eAnimationType from '../../animations/eAnimationType';
import selectors from '../../../redux/game/selectors';

export default class ControllerRingGirl extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.init();
    storeObserver.addListenerAsNew(modalSelectors.getModalData(eModalType.EMT_NEXT_OPPONENT), this.onModalDataChange);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize = (data) => {
    if (data.isMobile && data.isLandscape) {
      this.x = this.displayData.x;
      this.y = this.displayData.y;
    } else if (data.isMobile && data.isPortrait) {
      this.x = 696;
      this.y = -455;
    }
  }

  init = () => {
    this.container = this.getChildByName('container');
    this.container.y = this.container.displayData.y + this.container.displayData.height;
    this.ringGirlMask = this.getChildByName('ring_girl_mask');
    this.container.mask = this.ringGirlMask;
    this.roundText = this.container.getChildByName('round_amount');
    this.ringGirlAnimationContainer = this.container.getChildByName('animation');
    this.lightAnimationContainer = this.getChildByName('light_animation');
    this.title = this.container.getChildByName('ring_girl_title');
    this.visible = false;
    this.initAnimations();
    this.setTextStile();
  }

  initAnimations = () => {
    this.ringGirlAnimation = animationsLoader.createAnimation(eAnimationType.EAT_POPUPS);
    this.ringGirlAnimationContainer.addChild(this.ringGirlAnimation);
    this.lightAnimation = animationsLoader.createAnimation(eAnimationType.EAT_POPUPS);
    this.lightAnimation.alpha = 0;
    this.lightAnimationContainer.addChild(this.lightAnimation);
  }

  onModalDataChange = (data) => {
    if (this.visible === data.visible) return;
    data.visible ? setTimeout(this.show, 1500) : this.hide();
  }

  setTextStile = () => {
    this.roundText.style = { ...this.roundText.style, ...whiteBeigeGradient };
  }

  show = () => {
    this.visible = true;
    this.ringGirlAnimation.visible = true;
    this.lightAnimation.visible = true;
    this.ringGirlAnimation.state.setAnimation(0, ePopupAnimation.EPA_RING_GIRL_IDLE, true);
    this.lightAnimation.state.setAnimation(0, ePopupAnimation.EPA_SHINE_BG_GIRL, true);
    const state = store.getState();
    const myHealth = selectors.getMyHeals(state);
    this.title.text = myHealth === 0 ? window.OPWrapperService.localizations.getLocalizedText('ring_girl_next_revenge') : window.OPWrapperService.localizations.getLocalizedText('ring_girl_next_round_in');

    this.startCountdown();
    TweenMax.to(this.container, 0.5 , { y: this.container.displayData.y });
    TweenMax.to(this.lightAnimation, 0.5 , { alpha: 1 });
  }

  startCountdown = () => {
    this.setRoundText(3);
    setTimeout(() => {
      this.setRoundText(2);
    }, 834);
    setTimeout(() => {
      this.setRoundText(1);
    }, 1668);
    setTimeout(() => {
      this.setRoundText('0');
    }, 2502);
  }

  setRoundText = (value) => {
    this.roundText.text = value;
  }

  hide = () => {
    TweenMax.to(this.container, 0.5 , { y: 800, onComplete: () => {
      this.visible = false;
      this.ringGirlAnimation.visible = false;
      this.lightAnimation.visible = false;
    } });
    TweenMax.to(this.lightAnimation, 0.5 , { alpha: 0 });
  }

}
