import FlashLib from 'flashlib';
import { store, storeObserver } from '../..';
import AnimationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import eBoxersAnimationType from './eBoxersAnimationType';
import selectors from '../../redux/game/selectors';
import { eGameState } from '../../redux/game/reducer';
import eBoxersSkins from './eBoxersSkins';
import controllerSounds, { eSoundType } from '../sounds/controllerSounds';
import constants from '../../constants';
import { eScreenMode } from '../../enums';

export default class ControllerBoxers extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.skinIndex = selectors.getCurrentEnemy(store.getState()) % 3;
    this.soundName = eBoxersAnimationType.EBAT_IDLE_0;

    this.initAnimation();
    this.setmixes();
    this.addListeners();
  }

  initAnimation = () => {
    this.animation = AnimationsLoader.createAnimation(eAnimationType.EAT_BOXERS);
    this.animation.x = this.displayData.width / 2;
    this.animation.y = this.displayData.height;
    this.animation.visible = false;
    this.addChild(this.animation);
    this.animation.state.addListener({
      complete: ({ animation: { name } }) => {
        if ( this.enemyChanged && name === eBoxersAnimationType.EBAT_MEGA_WIN) {
          this.animation.state.clearTracks();
          return;
        }
        const index = Math.round(0.6 * Math.random());
        this.animation.state.addAnimation(0, eBoxersAnimationType[`EBAT_IDLE_${index}`], false, 0);
      },
      start: this.onStartAnimation
    });
  }

  addListeners = () => {
    storeObserver.addListener(selectors.getGameState, this.onChangedState);
    this.skinIndex = storeObserver.addListenerAndGetValue(selectors.getRealCurrentEnemy, this.onSkinChange);
    this.setSkin(eBoxersSkins[`EBS_SKIN_${this.skinIndex}`]);
    storeObserver.addListener(selectors.getBonusGameShowed, this.onBonusGameShowed);
    const gameScreen = storeObserver.addListenerAndGetValue(selectors.getGameScreen, this.onGameScreenChange);
    this.onGameScreenChange(gameScreen);
  }

  onGameScreenChange = (gameScreenState) => {
    const bonusGameShowed = selectors.getBonusGameShowed(store.getState());
    if (gameScreenState === eScreenMode.GAME && !bonusGameShowed && this.prevScreenState === eScreenMode.START) {
      this.animation.visible = true;
      this.animation.state.setAnimation(0, eBoxersAnimationType.EBAT_IDLE_1, false);
    }
    this.prevScreenState = gameScreenState;
  };

  onBonusGameShowed = (showed) => {
    if (showed) {
      setTimeout(() => this.animation.visible = false, 2000);
    } else {
      this.animation.state.setAnimation(0, eBoxersAnimationType.EBAT_IDLE_1, false);
      this.animation.visible = true;
    }
  }

  onChangedState = (state) => {
    if (state === this.gameState) return;
    this.gameState = state;
    if (this.gameState === eGameState.EGS_SHOW_WIN) {
      this.showAnimation();
    }
  }

  onSkinChange = (skinIndex) => {
    this.skinIndex = skinIndex;
    this.setSkin(eBoxersSkins[`EBS_SKIN_${this.skinIndex}`]);
    this.animation.skeleton.setToSetupPose();
    this.animation.state.setAnimation(0, eBoxersAnimationType.EBAT_IDLE_1, false);
  }

  showAnimation = () => {
    const state = store.getState();
    const betAmount = selectors.getBetAmount(state);
    const winAmount = selectors.getWinAmount(state);
    this.enemyChanged = selectors.getEnemyChanged(state);
    if (this.enemyChanged  || winAmount >= betAmount * constants.MULTIPLIER_MEGA_WIN) {
      this.setAnimation(eBoxersAnimationType.EBAT_MEGA_WIN);
      console.log('mega_win');
    } else if (winAmount >= betAmount * constants.MULTIPLIER_BIG_WIN) {
      this.setAnimation(eBoxersAnimationType.EBAT_BIG_WIN);
      console.log('big_win');
    } else if (winAmount > 0) {
      this.setAnimation(
        eBoxersAnimationType[`EBAT_WIN_LOW_${Math.round(2 * Math.random())}`]
      );
      console.log('low_win');
    } else if (winAmount === 0) {
      this.setAnimation(
        eBoxersAnimationType[`EBAT_WIN_MISHIT_${Math.round(3 * Math.random())}`]
      );
      console.log('win');
    }
  };

  setAnimation(type) {
    this.animation.state.setAnimation(0, type, false);
  }

  setSkin(name) {
    this.animation.skeleton.setSkinByName(name);
  }

  setmixes = () => {
    this.animation.stateData.defaultMix = 0.2;

    this.animation.stateData.setMix(eBoxersAnimationType.EBAT_MEGA_WIN, eBoxersAnimationType.EBAT_IDLE_0, 0.4);
    this.animation.stateData.setMix(eBoxersAnimationType.EBAT_MEGA_WIN, eBoxersAnimationType.EBAT_IDLE_1, 0.4);
  };

  onStartAnimation = (data) => {
    controllerSounds.stopSound(this.soundName);
    switch (data.animation.name) {
      case eBoxersAnimationType.EBAT_IDLE_0:
        this.soundName = eSoundType.EST_BOXER_IDLE_0;
        break;
      case eBoxersAnimationType.EBAT_IDLE_1:
        this.soundName = eSoundType.EST_BOXER_IDLE_1;
        break;
      case eBoxersAnimationType.EBAT_WIN_MISHIT_0:
        this.soundName = eSoundType.EST_BOXER_WIN_MISHIT_0;
        break;
      case eBoxersAnimationType.EBAT_WIN_MISHIT_1:
        this.soundName = eSoundType.EST_BOXER_WIN_MISHIT_1;
        break;
      case eBoxersAnimationType.EBAT_WIN_MISHIT_2:
        this.soundName = eSoundType.EST_BOXER_WIN_MISHIT_2;
        break;
      case eBoxersAnimationType.EBAT_WIN_MISHIT_3:
        this.soundName = eSoundType.EST_BOXER_WIN_MISHIT_3;
        break;
      case eBoxersAnimationType.EBAT_WIN_LOW_0:
        this.soundName = eSoundType.EST_BOXER_WIN_LOW_0;
        break;
      case eBoxersAnimationType.EBAT_WIN_LOW_1:
        this.soundName = eSoundType.EST_BOXER_WIN_LOW_1;
        break;
      case eBoxersAnimationType.EBAT_WIN_LOW_2:
        this.soundName = eSoundType.EST_BOXER_WIN_LOW_2;
        break;
      case eBoxersAnimationType.EBAT_BIG_WIN:
        this.soundName = eSoundType.EST_BOXER_BIG_WIN;
        break;
      case eBoxersAnimationType.EBAT_MEGA_WIN:
        this.soundName = eSoundType.EST_BOXER_MEGA_WIN;
        break;
    }
    controllerSounds.playSound(this.soundName);
  }
}
