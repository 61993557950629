import BaseController from '../base';

export default class BaseNotification extends BaseController {
  constructor({ type, _id }) {
    super({ type });

    this._id = _id;
    this._startTimestamp = null;
    this._complete = false;
    this._mountPosition = 'beforeend';
    this._lifeTime = 1000;
    this.COMPLETE_EVENT = `${this.controllerType}_complete`;

    this._trackLifeTime = this._trackLifeTime.bind(this);
    this.remove = this.remove.bind(this);
  }

  kill() {
    this._lifeTime = 0;
  }

  remove() {
    if (!this.root) throw new Error(`You must specify 'root' inside derived class`);
    this.beforeRemove();
    this.root.remove();
  }

  _onLifeTimeComplete() {
    this.emit(this.COMPLETE_EVENT, this);
  }

  _trackLifeTime(timestamp) {
    if (timestamp) {
      if (!this._startTimestamp) this._startTimestamp = timestamp
      if (timestamp - this._startTimestamp >= this._lifeTime) {
        this._startTimestamp = null;
        this._complete = true;
        this._onLifeTimeComplete();
        return;
      }
    }

    requestAnimationFrame(this._trackLifeTime);
  }

  get elementsIdPrefix() {
    return `notifications`
  }
}
