export default {
  CANVAS_WIDTH_LANDSCAPE: 2340,
  CANVAS_HEIGHT_LANDSCAPE: 1078,

  CANVAS_WIDTH_PORTRAIT: 2340,
  CANVAS_HEIGHT_PORTRAIT: 1080,

  SAFE_WIDTH_LANDSCAPE: 1920,
  SAFE_HEIGHT_LANDSCAPE: 1070,

  SAFE_WIDTH_PORTRAIT: 1060,
  SAFE_HEIGHT_PORTRAIT: 1800

};
