import FlashLib from 'flashlib';
import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import { TweenMax } from 'gsap';
import { store, storeObserver } from '../../index';
import selectors from '../../redux/game/selectors';
import controllerSounds, { eSoundType } from '../sounds/controllerSounds';
import { eScreenMode } from '../../enums';

export default class ControllerReferee extends FlashLib.MovieClip {
  // constructor(data, displayData) {
  //   super(data, displayData);
  //   this.init();
  //   storeObserver.addListener(selectors.getGameState, this.onGameStateChenged);
  // }
  // init = () => {
  //   this.animationContainer = this.getChildByName('animationContainer');
  //   this.initAnimation();
  //   this.hideAndTryResetTimeOut();
  // }
  // initAnimation = () => {
  //   this.animation = animationsLoader.createAnimation(eAnimationType.EAT_REFERRY);
  //   this.animation.y = this.animation.height;
  //   this.animation.state.setAnimation(0, 'referry_pointing', true);
  //   this.animation.on('pointertap', this.hideAndTryResetTimeOut);
  //   this.animation.interactive = true;
  //   this.animation.buttonMode = true;
  //   this.animation.visible = false;
  //   this.animationContainer.addChild(this.animation);
  // }
  //
  // onGameStateChenged = () => {
  //   this.hideAndTryResetTimeOut();
  // }
  //
  // hideAndTryResetTimeOut = () => {
  //   const state = store.getState();
  //   const freespinsEnabled = selectors.getFreespinsEnabled(state);
  //   this.hide();
  //   clearTimeout(this.timeout);
  //   if (!freespinsEnabled) {
  //     this.resetTimeout();
  //   }
  // }
  //
  // resetTimeout = () => {
  //   clearTimeout(this.timeout);
  //   this.timeout = setTimeout(this.show, 40000);
  // }
  //
  // show = () => {
  //   this.animation.visible = true;
  //   TweenMax.killTweensOf(this.animation);
  //   this.sayHey();
  //   this.heyTimeout = setInterval(this.sayHey, 30000);
  //   TweenMax.to(this.animation, 0.5, { y: 0 });
  // }
  //
  // sayHey = () => {
  //   const startScreenMode = selectors.getGameScreen(store.getState());
  //   if (startScreenMode === eScreenMode.START) return;
  //   controllerSounds.playSound(eSoundType.EST_HEY, 0.8);
  // }
  //
  // hide = () => {
  //   clearTimeout(this.heyTimeout);
  //   TweenMax.killTweensOf(this.animation);
  //   TweenMax.to(this.animation, 0.5, { y: this.animation.height, onComplete: () => this.animation.visible = false });
  // }

}
