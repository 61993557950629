import FlashLib from 'flashlib';
import eSymbolType from './eSymbolType';
import eAnimationType from '../animations/eAnimationType';
import AnimationLoader from '../animations/animationsLoader';
import symbolAnimationsKeys from './symbolAnimationsKeys';
import { storeObserver } from '../..';
import selectors from '../../redux/game/selectors';
import { eGameState } from '../../redux/game/reducer';

export default class ControllerSymbol extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.offsets = {
      [eSymbolType.EST_BONUS]: { x: -2.5, y: -1 },
      [eSymbolType.EST_HIGH_0]: { x: -2.5, y: -1 },
      [eSymbolType.EST_HIGH_1]: { x: -1.5, y: -1 },
      [eSymbolType.EST_HIGH_2]: { x: -2.5, y: -1 },
      [eSymbolType.EST_LOW_0]: { x: -2.5, y: -1 },
      [eSymbolType.EST_LOW_1]: { x: -2.5, y: -1 },
      [eSymbolType.EST_LOW_2]: { x: -2.5, y: -1 },
      [eSymbolType.EST_LOW_3]: { x: -2.5, y: -1 },
      [eSymbolType.EST_WILD]: { x: -0.5, y: 0 },
      [eSymbolType.EST_SCATTER]: { x: 1.5, y: 1 },
    };

    this.isFirstRunAnimation = true;
    this.animationVisible = false;
    this.symbolVisible = true;

    this.symbols = {};
    for (let key in eSymbolType) {
      const item = this.getChildByName(eSymbolType[key]);
      this.symbols[eSymbolType[key]] = item;
      this.symbols[eSymbolType[key]].visible = false;
    }
    this.animation = AnimationLoader.createAnimation(eAnimationType.EAT_SYMBOLS);
    this.animation.x = this.displayData.width / 2;
    this.animation.y = this.displayData.height / 2;
    this.animation.visible = false;
    this.animation.state.addListener({
      complete: (data) => {
        const name = data.animation.name;
        if (name === `${symbolAnimationsKeys[`${this.type}_START`]}`) return;
        setTimeout(()=> {
          this.animation.visible = false;
        },1);
        this.symbols[this.type].visible = true;
      }
    });
    this.addChild(this.animation);

    storeObserver.addListener(selectors.getGameState, this.onGameStateChanged);
    storeObserver.addListener(selectors.getBonusGameShowed, this.onBonusGameShowed);
    this.setSymbol(eSymbolType.EST_WILD);
  }

  onBonusGameShowed = (showed) => {
    if (showed) {
      this.animationVisible = this.animation.visible;
      this.symbolVisible = this.symbols[this.type].visible;

      setTimeout(() => {
        this.animation.visible = false;
        this.symbols[this.type].visible = true;
      }, 2000);
    } else {
      this.symbols[this.type].visible = this.symbolVisible;
      this.animation.visible = this.animationVisible;
    }
  };

  onGameStateChanged = (state) => {
    if (state === eGameState.EGS_WAITING_FOR_RESPONSE) {
      this.isFirstRunAnimation = true;
      this.animation.visible = false;
      this.symbols[this.type].visible = true;
      this.symbols[this.type].goToFrame(1);
    }
  }

  setSymbol(type) {

    this.type = type;
    if (this.animationInterval) clearInterval(this.animationInterval);
    this.animation.x = this.displayData.width / 2 + this.offsets[this.type].x;
    this.animation.y = this.displayData.height / 2 + this.offsets[this.type].y;

    for (let key in this.symbols) {
      this.symbols[key].visible = key === type;
    }
  }

  animateSymbol() {
    this.symbols[this.type].visible = false;
    this.animation.visible = true;
    if ((this.type === eSymbolType.EST_WILD || this.type === eSymbolType.EST_SCATTER || this.type === eSymbolType.EST_BONUS) && this.isFirstRunAnimation) {
      this.symbols[this.type].goToFrame(2);
      this.animation.state.setAnimation('0', `${symbolAnimationsKeys[`${this.type}_START`]}`, false);
      this.animation.state.addAnimation('0', symbolAnimationsKeys[this.type], false);
      this.isFirstRunAnimation = false;
    } else {
      this.animation.state.setAnimation('0', symbolAnimationsKeys[this.type], false);
    }
  }

}
