import ModalBase from '../../base/modalBase';
import eModalType from '../eModalType';
import animationsLoader from '../../animations/animationsLoader';
import eAnimationType from '../../animations/eAnimationType';
import ePopupBonusPurchaseAnimation from './ePopupBonusPurchaseAnimation';
import ControllerBetSelector from '../../ui/controllerBetSelector';
import * as PIXI from 'pixi.js';
import { TweenMax } from 'gsap';
import { store } from '../../../index';
import actions from '../../../redux/game/actions';
import { eScreenMode } from '../../../enums';

export default class PopupBonusPurchase extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;
    this.type = eModalType.EMT_BONUS_PURCHASE;
    this.defaultPositions = {};
    this.wrapper = this.getChildByName('container');
    this.description = this.wrapper.getChildByName('desc_container');
    this.description.centring();
    this.btnClose = this.wrapper.getChildByName('close');
    this.defaultPositions.btnClose = { x: 1188, y: this.btnClose.y };
    this.btnClose.x = this.defaultPositions.btnClose.x;
    this.btnClose.addListener('pointertap', this.onCloseClicked);
    this.betSelector = this.wrapper.getChildByName('bet_selector_container');
    new ControllerBetSelector(this.betSelector.getChildByName('cashBet'));
    this.card = this.wrapper.getChildByName('card_0');
    this.card.modal = this.getChildByName('modal');
    // const bonusTypes = Object.keys(eBonusType);
    // for (let i = 0; i < bonusTypes.length; i++) {
    //   const type = bonusTypes[i];
    //   this.cards.push(new ControllerBonusPurchaseCard(this, eBonusType[type], i));
    // }
    this.initListener();
    this.showBlackout();
    this.initAnimationTitle();

    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize = (data) => {
    if (data.isMobile && data.isLandscape) {
      this.wrapper.anchorDefault();
      this.wrapper.scale.set(1);

      this.card.y = this.card.displayData.y;

      this.titleAnimation.y = 60;

      this.btnClose.x = this.defaultPositions.btnClose.x;
      this.btnClose.y = this.defaultPositions.btnClose.y;

      this.betSelector.y = this.betSelector.displayData.y;
      this.description.scale.set(this.description.displayData.scaleX);

    } else if (data.isMobile && data.isPortrait) {

      this.wrapper.centring();
      this.wrapper.scale.set(1.2);

      this.card.y = this.card.displayData.y + 100;

      this.titleAnimation.y = 0;
      this.btnClose.x = 675;
      this.btnClose.y = -250;

      this.betSelector.y = this.betSelector.displayData.y + 250;
      this.description.scale.set(0.74);
    }

  }

  onShowed = () => {
    store.dispatch(actions.setScreenMode(eScreenMode.POPUP));
    this.alpha = 0;
    TweenMax.to(this, 0.3 , { alpha: 1 });
    this.card.start();
    this.showTitleStartAnimation();
  }

  initAnimationTitle = () => {
    this.titleAnimation = animationsLoader.createAnimation(eAnimationType.EAT_BONUS_PURCHASE);
    this.titleAnimation.x = this.wrapper.displayData.width / 2;
    this.titleAnimation.y = 60;
    this.titleAnimation.visible = false;
    this.titleAnimation.state.addListener({
      complete: () => {
        this.titleAnimation.state.setAnimation(0, ePopupBonusPurchaseAnimation.EPBPA_TITLE_IDLE, true);
      }
    });
    this.wrapper.addChild(this.titleAnimation);
  }

  showTitleStartAnimation = () => {
    // this is hack for pixi-spine shit
    this.titleAnimation.visible = true;
    this.titleAnimation.state.setAnimation(0, ePopupBonusPurchaseAnimation.EPBPA_TITLE_START, false);
  }

  showBlackout() {
    const blackout = new PIXI.Graphics();
    blackout.beginFill(0x000000, 0.8);
    blackout.drawRect(0, -630, 2340, 2340);
    blackout.endFill();
    this.addChildAt(blackout, 0);
  }

  onCloseClicked = () => {
    this.hide();
  };

  close() {
    this.titleAnimation.visible = false;
    this.card.stop();
    super.close();
  }

  onClosed = () => {
    store.dispatch(actions.setScreenMode(eScreenMode.GAME));
  }
}
