import FlashLib from 'flashlib';
import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import eBoxerAnimation from './eBoxerAnimation';
import controllerSounds, { eSoundType } from '../sounds/controllerSounds';

export default class ControllerBonusGameBoxers extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.initAnimation();
    this._initMixins();
  }
  initAnimation = () => {
    this.animation = animationsLoader.createAnimation(eAnimationType.EAT_BONUS_GAME_BOXERS);
    this.animation.state.addListener({
      complete: ({ animation: { name } }) => {
        if (name === eBoxerAnimation.EBA_START_0 || name === eBoxerAnimation.EBA_START_1) {
          this._addStart();
        } else if (name === eBoxerAnimation.EBA_WIN_0 || name === eBoxerAnimation.EBA_WIN_1) {
          this._addIdle();
          this.onWinCallback();
        } if (name === eBoxerAnimation.EBA_EXIT) {
          this.onExitCallback();
        } else {
          this._addIdle();
        }
      },
      start: this.onStartAnimation
    });
    this.addChild(this.animation);
  }

  _initMixins = () => {
    this.animation.stateData.defaultMix = 0.2;
  }

  showIdle = () => {
    const index = Math.round(2 * Math.random());
    this.animation.state.clearTracks();
    this.animation.skeleton.setToSetupPose();
    this.animation.state.setAnimation(0, eBoxerAnimation[`EBA_IDLE_${index}`], false);
  }

  showStart = () => {
    const index = Math.round(1 * Math.random());
    this.animation.state.setAnimation(0, eBoxerAnimation[`EBA_START_${index}`], false);
  }

  showWin = () => {
    const index = Math.round(1 * Math.random());
    this.animation.state.setAnimation(0, eBoxerAnimation[`EBA_WIN_${index}`], false);
    return new Promise((resolve => this.onWinCallback = () => resolve()));
  }

  showExit = (callback = () => {}) => {
    this.onExitCallback = callback;
    this.animation.state.clearTracks();
    this.animation.state.setAnimation(0, eBoxerAnimation.EBA_EXIT, false);
  }
  _addIdle = () => {
    const index = Math.round(2 * Math.random());
    this.animation.state.addAnimation(0, eBoxerAnimation[`EBA_IDLE_${index}`], false, 1);
  }

  _addStart = () => {
    const index = Math.round(1 * Math.random());
    this.animation.state.addAnimation(0, eBoxerAnimation[`EBA_START_${index}`], false, 1);
  }
  onStartAnimation = (data) => {
    if (this.soundName) controllerSounds.stopSound(this.soundName);
    this.soundName = null;
    switch (data.animation.name) {
      case eBoxerAnimation.EBA_IDLE_2:
        this.soundName = eSoundType.EST_BONUS_BOXER_IDLE_2;
        break;
      case eBoxerAnimation.EBA_WIN_0:
        this.soundName = eSoundType.EST_BONUS_BOXER_WIN_0;
        break;
      case eBoxerAnimation.EBA_WIN_1:
        this.soundName = eSoundType.EST_BONUS_BOXER_WIN_1;
        break;
      case eBoxerAnimation.EBA_START_0:
        this.soundName = eSoundType.EST_BONUS_BOXER_START_0;
        break;

      case eBoxerAnimation.EBA_START_1:
        this.soundName = eSoundType.EST_BONUS_BOXER_START_1;
        break;

    }
    if (this.soundName) controllerSounds.playSound(this.soundName);
  }
}
