export default {
  EPA_BIG_WIN_IDLE: 'big_win_idle',
  EPA_BIG_WIN_START: 'big_win_start',

  EPA_MEGA_WIN_IDLE: 'mega_win_idle',
  EPA_MEGA_WIN_START: 'mega_win_start',

  EPA_YOU_WON_IDLE: 'you_won_idle',
  EPA_YOU_WON_START: 'you_won_start',
  EPA_NEXT_OPPONENT_START: 'next_opponent_start',
  EPA_NEXT_OPPONENT_IDLE: 'next_opponent_idle',
  EPA_NEXT_OPPONENT_END: 'next_opponent_end',
  EPA_RING_GIRL_IDLE: 'girl_idle',
  EPA_SHINE_BG_GIRL: 'shine_bg_girl',
};
