export default {
  EMT_RULES: 'rules',
  EMT_WIN_BIG: 'winBig',
  EMT_WIN: 'win',
  EMT_WIN_REGULAR: 'winRegular',
  EMT_WIN_FREE_SPINS: 'winFreeSpins',
  EMT_TOTAL_WIN: 'totalWin',
  EMT_PROVABLY_FAIR: 'provablyFair',
  EMT_SETTINGS: 'settings',
  EMT_PAYTABLE: 'paytable',
  EMT_INFO: 'info',
  EMT_BONUS_PURCHASE: 'bonusPurchase',
  EMT_LEADERBOARD: 'leaderboard',
  EMT_NEXT_OPPONENT: 'nextOpponent',
  EMT_WON_BONUS_GAME: 'wonBonusGame',
};
