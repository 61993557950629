export const getFromStorage = (name) => {
  let result = null;
  try {
    result = JSON.parse(localStorage.getItem(name));
  } catch (e) {
    console.warn(e.message);
  }
  return result;
};

export const setToStorage = (name, value) => {
  try {
    localStorage.setItem(name, value);
  } catch (e) {
    console.warn(e.message);
  }
};
