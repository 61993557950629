import FlashLib from 'flashlib';
import ControllerButton from './controllers/base/controllerButton';
// import ModalBase from './controllers/base/modalBase';
import ControllerSlot from './controllers/slot/controllerSlot';
import ControllerReel from './controllers/reel/controllerReel';
import ControllerSymbol from './controllers/symbol/controllerSymbol';
import ControllerUi from './controllers/ui/controllerUi';
import ModalSettings from './controllers/popups/modalSettings';
import ControllerSwitch from './controllers/base/controllerSwitch';
import ModalPaytable from './controllers/popups/modalPaytable';
import ModalBigWin from './controllers/popups/modalBigWin/modalBigWin';
import ModalInfo from './controllers/popups/modalInfo';
import ControllerBlackout from './controllers/controllerBlackout';
import ModalFreeSpins from './controllers/popups/modalFreeSpins';
import ModalTotalWin from './controllers/popups/modalTotalWin';
import ControllerBoxers from './controllers/boxers/ControllerBoxers';
import ControllerBackground from './controllers/background/controllerBackground';
import ControllerHealthBars from './controllers/healthBars/controllerHealthBars';
import ControllerBonusGame from './controllers/bonusGame/controllerBonusGame';
import ControllerBonusButton from './controllers/ui/controllerBonusButton';
import ControllerBonusGameField from './controllers/bonusGame/controllerBonusGameField';
import ControllerBonusGameBoxers from './controllers/bonusGame/controllerBonusGameBoxers';
import ControllerReferee from './controllers/referry/controllerReferee';
import ControllerBlackoutFull from './controllers/controllerBlackoutFull';
import ControllerBonusPurchaseModal from './controllers/popups/popupBonusPurchase/ControllerBonusPurchaseModal';
import PopupBonusPurchase from './controllers/popups/popupBonusPurchase/popupBonusPurchase';
import ControllerBonusPurchaseCard from './controllers/popups/popupBonusPurchase/ControllerBonusPurchaseCard';
import ModalNextOpponent from './controllers/popups/nextOpponent/modalNextOpponent';
import ControllerBonusGameMultipliers from './controllers/bonusGame/controllerBonusGameMultipliers';
import ModalWin from './controllers/popups/modalWin';
import ModalWonBonusGame from './controllers/popups/modalWonBonusGame';
import ControllerRingGirl from './controllers/popups/nextOpponent/controllerRingGirl';

FlashLib.registerClass('Button', ControllerButton);
// FlashLib.registerClass('ControllerPopup', ModalBase);
FlashLib.registerClass('ControllerSlot', ControllerSlot);
FlashLib.registerClass('ControllerReel', ControllerReel);
FlashLib.registerClass('ControllerSymbol', ControllerSymbol);
FlashLib.registerClass('ControlPanel', ControllerUi);
FlashLib.registerClass('ModalSettings', ModalSettings);
FlashLib.registerClass('ModalPaytable', ModalPaytable);
FlashLib.registerClass('Switch', ControllerSwitch);
FlashLib.registerClass('ModalBigWin', ModalBigWin);
FlashLib.registerClass('ModalFreeSpins', ModalFreeSpins);
FlashLib.registerClass('ModalTotalWin', ModalTotalWin);
FlashLib.registerClass('ControllerBlackout', ControllerBlackout);
FlashLib.registerClass('ControllerBlackoutFull', ControllerBlackoutFull);
FlashLib.registerClass('ModalInfo', ModalInfo);
FlashLib.registerClass('ControllerBoxers', ControllerBoxers);
FlashLib.registerClass('ControllerBackground', ControllerBackground);
FlashLib.registerClass('ControllerHealthBars', ControllerHealthBars);
FlashLib.registerClass('ControllerBonusGame', ControllerBonusGame);
FlashLib.registerClass('ControllerBonusButton', ControllerBonusButton);
FlashLib.registerClass('ControllerBonusGameField', ControllerBonusGameField);
FlashLib.registerClass('ControllerBonusGameBoxers', ControllerBonusGameBoxers);
FlashLib.registerClass('ControllerReferee', ControllerReferee);
FlashLib.registerClass('ControllerBonusPurchaseModal', ControllerBonusPurchaseModal);
FlashLib.registerClass('PopupBonusPurchase', PopupBonusPurchase);
FlashLib.registerClass('ControllerBonusPurchaseCard', ControllerBonusPurchaseCard);
FlashLib.registerClass('ModalNextOpponent', ModalNextOpponent);
FlashLib.registerClass('ControllerBonusGameMultipliers', ControllerBonusGameMultipliers);
FlashLib.registerClass('ModalWin', ModalWin);
FlashLib.registerClass('ModalWonBonusGame', ModalWonBonusGame);
FlashLib.registerClass('ControllerRingGirl', ControllerRingGirl);

