import animationsLoader from '../../animations/animationsLoader';
import eAnimationType from '../../animations/eAnimationType';
import ePopupBonusPurchaseAnimation from './ePopupBonusPurchaseAnimation';
import * as PIXI from 'pixi.js';
import TweenMax, { Elastic } from 'gsap';
import { whiteYeelowOrange } from '../../../fontStyles';
import { store, storeObserver } from '../../../index';
import selectors from '../../../redux/game/selectors';
import ParticleSystem from '../../../utils/particleSystem';
import particleConfigBonusPurchase from './particleConfigBonusPurchase';
import FlashLib from 'flashlib';
import { formatCurrency } from '../../../utils/currency';

export default class ControllerBonusPurchaseCard extends FlashLib.MovieClip {
  constructor(data, $displayItemData) {
    super(data, $displayItemData);
    this.index = 0;
    this.pricePanel = this.getChildByName(`pricePanel${this.index}`);
    this.priceText = this.pricePanel.getChildByName('text');
    this.pricePanel.pivot.set(this.pricePanel.width / 2, this.pricePanel.height / 2);
    this.pricePanel.x += this.pricePanel.width / 2;
    this.pricePanel.y += this.pricePanel.height / 2;
    this.clicked = false;

    this.initAnimations();

    storeObserver.addListener(selectors.getBetAmount, this.updatePrice);
    this.updatePrice();
    this.initBuyButtons();
    this.initAnimationButtons();
    this.setTextStyle();
    this.scaledItems = [this.animation.scale, this.text.scale, this.pricePanel.scale];
  }
  initBuyButtons = () => {
    this.buyButton0 = this.getChildByName('buy_0');
    const hitArea = new PIXI.Rectangle(
      0,
      0,
      this.buyButton0.width,
      this.buyButton0.height,
    );
    this.buyButton0.hitArea = hitArea;
    this.buyButton0.addListener('pointertap', () => this.onBuyButtonClick());
    this.buyButton0.addListener('mouseover', () => this.onBuyButtonOver());
    this.buyButton0.addListener('mouseout', () => this.onBuyButtonOut());
    const label = this.buyButton0.getChildByName('popup_bonus_purchase_btn_buy');
    label.correctPosition('center', 'center');
  }
  onBuyButtonClick = () => {
    this.onAnimationClick();
  }
  onBuyButtonOver = () => {
    this.onAnimationOver();
  }
  onBuyButtonOut = () => {
    this.onAnimationOut();
  }

  initAnimationButtons = () => {

    this.animationButtonBuy0 = animationsLoader.createAnimation(eAnimationType.EAT_BONUS_PURCHASE);
    this.animationButtonBuy0.x = this.buyButton0.displayData.width / 2;
    this.animationButtonBuy0.y = this.buyButton0.displayData.height / 2 - 2;
    this.animationButtonBuy0.visible = false;
    // animation.state.setAnimation(0, ePopupBonusPurchaseAnimation.EPBPA_BUY_BUTTON_SIDE, true);
    this.buyButton0.addChildAt(this.animationButtonBuy0, 1);
  }

  showBuyButtonAnimation = () => {
    const delay = 6000;
    this.buyButtonTimeout = setInterval(() => {
      this.animationButtonBuy0.visible = true;
      this.animationButtonBuy0.state.setAnimation(0, ePopupBonusPurchaseAnimation.EPBPA_BUY_BUTTON_SIDE, false);
    }, delay);
  }

  stopBuyButtonAnimation = () => {
    this.animationButtonBuy0.visible = false;
    clearTimeout(this.buyButtonTimeout);
  }

  updatePrice = () => {
    const state = store.getState();
    const amount = selectors.getBonusPrices(state);
    const currencyInfo = selectors.getCurrencyInfo(state);
    this.priceText.text = formatCurrency(amount, currencyInfo.decimal) + ` ${currencyInfo.currency}`;
  };

  initAnimations = () => {
    this.animation = animationsLoader.createAnimation(eAnimationType.EAT_BONUS_PURCHASE);
    this.animation.x = this.displayData.width / 2;
    this.animation.y = this.displayData.height / 2 - 144;
    this.animation.buttonMode = true;
    this.animation.visible = false;

    this.animation.state.addListener({
      complete: () => {
        this.animation.state.setAnimation(0, ePopupBonusPurchaseAnimation.EPBPA_CARD_IDLE, true);
      }
    });
    this.animation.state.setAnimation(0, ePopupBonusPurchaseAnimation.EPBPA_CARD_IDLE, true);
    this.addChildAt(this.animation, 0);
    const rect = new PIXI.Rectangle(
      -202,
      -310,
      404,
      510
    );

    this.animation.hitArea = rect;
    this.animation.interactive = true;

    this.animation.on('mouseover', this.onAnimationOver, this);
    this.animation.on('mouseout', this.onAnimationOut, this);
    this.animation.on('pointertap', this.onAnimationClick, this);

  };
  onAnimationOver = () => {
    if (this.clicked) return;
    TweenMax.killTweensOf(this.scaledItems);
    const duration = 0.4;
    TweenMax.to(this.scaledItems, duration, {
      x: 1.08,
      y: 1.08
    });
  };
  onAnimationOut = () => {
    if (this.clicked) return;
    TweenMax.killTweensOf(this.scaledItems);
    const duration = 0.4;
    TweenMax.to(this.scaledItems, duration, { x: 1, y: 1 });
  };
  onAnimationClick = () => {
    if (this.clicked) return;
    this.animation.state.setAnimation(0, ePopupBonusPurchaseAnimation.EPBPA_CARD_BUY, true);
    TweenMax.killTweensOf(this.scaledItems);

    this._animateClickIn();
  };

  setTextStyle() {
    this.text = this.getChildByName('popup_bonus_purchase_card_title');
    this.text.pivot.set(this.text.width / 2, this.text.height / 2);
    this.text.x += this.text.width / 2;
    this.text.y += this.text.height / 2;
    this.text.style = { ...this.text.style, ...whiteYeelowOrange };

    const text = this.buyButton0.getChildByName('popup_bonus_purchase_btn_buy');
    text.style = {
      ...text.style,
      dropShadow: true,
      dropShadowAlpha: 0.8,
      dropShadowBlur: 4
    };
  }

  _animateClickIn = () => {
    this.clicked = true;
    const scale = 0.16;
    const timeStart = Date.now();
    TweenMax.to(this.scaledItems, 0.1, {
      x: this.animation.scale.x - scale,
      y: this.animation.scale.y - scale,
      onComplete: () => {
        this._animateClickOut(scale, timeStart);
      },
    });
  };

  _animateClickOut = (scale, timeStart) => {
    TweenMax.to(this.scaledItems, 1, {
      ease: Elastic.easeOut.config(1, 0.3),
      x: this.animation.scale.x + scale / 2,
      y: this.animation.scale.y + scale / 2,
      onUpdate: () => {
        if (Date.now() - timeStart >= 0.6 * 1000) {
          this._onClickCallback();
        }
      },
      onComplete: this.onAnimationOut,
    });
  };

  _onClickCallback() {
    this.clicked = false;
    this.modal.show(this.bonusGameType);
  }

  start = () => {
    this.updatePrice();
    this.showPartcles();
    this.showBuyButtonAnimation();
    this.animation.visible = true;
  }

  stop = () => {
    this.stopParticles();
    this.animation.visible = false;
    this.stopBuyButtonAnimation();
  }

  showPartcles = () => {
    const texture = PIXI.Texture.from('starsParticle');
    const width = 200;
    for (let i = 0; i < 3; i++) {
      const container = new PIXI.Container();
      container.pivot.x = width / 2;
      container.x = this.displayData.width / 2;
      container.y = -266;
      if (!this[`particle${i}`]) {
        this[`particle${i}`] = new ParticleSystem(container, { texture: texture }, {
          ...particleConfigBonusPurchase,
          x0max: width, // px
        });
        this.addChildAt(container, 0);
      } else {
        this[`particle${i}`].start();
      }
    }
  }

  stopParticles = () => {
    for (let i = 0; i < 3; i++) {
      this[`particle${i}`].stop();
    }
  }
}
