import FlashLib from 'flashlib';
import { exponentiationEaseOut } from '../../utils/JSAnimations';
import { store, storeObserver } from '../../index';
import selectors from '../../redux/game/selectors';
import { eGameState } from '../../redux/game/reducer';
import actionsModals from '../../redux/modals/actions';
import eModalType from '../popups/eModalType';
import { TweenMax, Power3, TimelineLite, Linear, Power1, Power2, Power4 } from 'gsap';
import constants from '../../constants';
import { whiteBeigeGradient } from '../../fontStyles';

export default class ControllerHealthBars extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.maxHealth = 50;
    this.countAnimateBlueBar = 0;
    this.powers = [Power1.easeOut, Power2.easeOut, Power3.easeOut, Power4.easeOut];
    this.init();

    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );

  }

  onResize = (data) => {
    this.isPortrait = data.isPortrait;
  }
  init = () => {
    this.redHealthBars = this.getChildByName('red_health_bar');
    this.blueHealthBars = this.getChildByName('blue_health_bar');
    this.blueFilter = this.blueHealthBars.getChildByName('filter');
    this.blueFilter.alpha = 0;
    this.redFilter = this.redHealthBars.getChildByName('filter');
    this.redFilter.alpha = 0;
    this.hitPowerAmountText = this.getChildByName('hitPower');
    this.hitPowerAmountText.visible = false;
    this.redMask = new PIXI.Graphics( );
    this.blueMask = new PIXI.Graphics( );
    this.addChild(this.redMask, this.blueMask);
    this.redHealthBars.mask = this.redMask;
    this.blueHealthBars.mask = this.blueMask;
    this.redHealthProgress = 1;
    this.setRedHealth(this.redHealthProgress);
    this.blueHealthProgress = 1;
    this.setBlueHealth(this.blueHealthProgress);
    this.currentEnemy = selectors.getCurrentEnemy(store.getState());
    this.addListeners();

  }

  addListeners = () => {
    storeObserver.addListenerAndGetValue(selectors.getGameState, this.onGameStateChanged);
    this.maxEnemyHealth = storeObserver.addListenerAndGetValue(selectors.getEnemyMaxHeals, (value) => this.maxEnemyHealth = value);
    this.update();
  }

  onGameStateChanged = (state) => {
    if (this.gameState === state) return;
    this.gameState = state;
    if (state === eGameState.EGS_UPDATE_HEALTH) {
      this.update();
    } else if (state === eGameState.EGS_SHOW_WIN) {
      this.showAnimationHitPower();
    }
  }

  showAnimationHitPower = () => {
    const state = store.getState();
    const freespinsEnabled = selectors.getFreespinsEnabled(state);
    const isLastFreespin = selectors.getIsLastFreespin(state);
    const bonusGameShowed = selectors.getBonusGameShowed(state);

    if (freespinsEnabled || isLastFreespin || bonusGameShowed) return;
    const myHeals = selectors.getMyHeals(state);
    const enemyHeals = selectors.getEnemyHeals(state);
    const myPower = -Math.round((this.redHealthProgress - myHeals / this.maxHealth) * 10000);
    const enemyPower = myPower ? null : -Math.round((this.blueHealthProgress - enemyHeals / this.maxEnemyHealth) * 10000);

    let hitPower = myPower || enemyPower;
    if (hitPower >= 0) return;
    const offset = myPower ? -400 : 400;
    const offsetEnd = myPower ? -100 : 100;
    const betAmount = selectors.getBetAmount(state);
    const winAmount = selectors.getWinAmount(state);
    const duration = winAmount < betAmount * constants.MULTIPLIER_BIG_WIN ? 6 : 15;

    const text = new PIXI.Text('', { ...this.hitPowerAmountText.style, ...whiteBeigeGradient });
    text.anchor.set(0.5);
    text.x = this.hitPowerAmountText.displayData.x + this.hitPowerAmountText.displayData.width / 3 + offset;
    text.y = this.isPortrait ? -700 : -250;
    text.text = hitPower;
    text.alpha = 1;
    this.addChild(text);
    (new TimelineLite)
      .to(text.scale, 0.025, {x: 1.2 , y: 1.2, yoyo: true, repeat: 1})
      .to(text, duration / 72, { x: `+=${offsetEnd / 4 }`, ease: Linear.easeNone }, 0)
      .to(text, duration / 72, { y: '-=13', ease: Linear.easeNone }, 0)
      .to(text, duration * 2, { x: `+=${offsetEnd * 2}`, alpha: 0, ease: this.powers[Math.floor(5 * Math.random())] }, 'end')
      .to(text, duration * 2, { y: '-=600' }, 'end')
      .call(() => text.destroy());

  }

  update = () => {
    const state = store.getState();
    const myHeals = selectors.getMyHeals(state);
    let enemyHeals = selectors.getEnemyHeals(state);
    const currentEnemy = selectors.getCurrentEnemy(state);
    let isNeedChangeEnemy;
    let myProgress = myHeals / this.maxHealth;
    if (currentEnemy !== this.currentEnemy) {
      this.currentEnemy = currentEnemy;
      isNeedChangeEnemy = true;
      enemyHeals = 0;
      myProgress = this.redHealthProgress;
    }
    const enemyProgress = myHeals === 0 ? this.blueHealthProgress : enemyHeals / this.maxEnemyHealth;

    this.updateRedHealth(myProgress);
    this.updateBlueHealth(enemyProgress, isNeedChangeEnemy);
  }

  updateRedHealth = (progress) => {
    if (this.redHealthProgress === progress) return;
    if (progress === 0 ) store.dispatch(actionsModals.showModal({ type: eModalType.EMT_NEXT_OPPONENT }));
    TweenMax.fromTo(this.redFilter, 0.2,{ alpha: 0 }, { alpha: 1, repeat: 5, yoyo: true });
    exponentiationEaseOut(this.redHealthProgress, progress, this.setRedHealth, 2000,() => this.onUpdatedRedHealth(progress) );
    this.redHealthProgress = progress;
  }

  onUpdatedRedHealth = (progress) => {
    this.redSaturate = 0;
    if (progress === 0 ) {
      this.updateRedHealth(1);
      this.updateBlueHealth(1);
    }
  }
  updateBlueHealth = (progress, isNeedChangeEnemy) => {
    if (this.blueHealthProgress === progress) return;
    TweenMax.fromTo(this.blueFilter, 0.2, { alpha: 0 }, { alpha: 1, repeat: 5, yoyo: true });
    exponentiationEaseOut(this.blueHealthProgress, progress, this.setBlueHealth, 2000, () => this.onUpdatedBlueHealth(isNeedChangeEnemy) );
    this.blueHealthProgress = progress;
  }

  onUpdatedBlueHealth = (isNeedChangeEnemy) => {
    this.blueSaturate = 0;
    if (isNeedChangeEnemy) {
      setTimeout(() => {
        store.dispatch(actionsModals.showModal({ type: eModalType.EMT_NEXT_OPPONENT }));
        this.update();
      }, 500);
    }
  }

  setRedHealth = (progress) => {
    this.redMask.clear();
    this.redMask.beginFill(0x550000);
    this.redMask.drawRect(this.redHealthBars.displayData.x, this.redHealthBars.displayData.y, this.redHealthBars.displayData.width * progress, this.redHealthBars.displayData.height);
    this.redMask.endFill();
  }

  setBlueHealth = (progress) => {
    this.blueMask.clear();
    this.blueMask.beginFill(0x550000);
    this.blueMask.drawRect(this.blueHealthBars.displayData.x + this.blueHealthBars.displayData.width, this.blueHealthBars.displayData.y, -this.blueHealthBars.displayData.width * progress, this.blueHealthBars.displayData.height);
    this.blueMask.endFill();
  }
}
