import FlashLib from 'flashlib';
import { store, storeObserver } from '../../index';
import selectors from '../../redux/modals/selectors';
import TweenMax from 'gsap';
import actionsModals from '../../redux/modals/actions';

export default class ModalBase extends FlashLib.MovieClip {
  constructor(data, $displayItemData) {
    super(data, $displayItemData);

    this.visible = false;

    this.onShowed = () => {};
    this.onClosed = () => {};

    this.type = 'base';
  }

  initListener = () => {
    storeObserver.addListenerAsNew(selectors.getModalData(this.type), this.onModalDataChanged);
  };

  initBlocker = () => {
    // 0 is blocker, 1 - popup background
    this.children[0].interactive = true;
    this.children[1].interactive = true;
    this.children[0].on('pointertap', this.onCloseClicked);
  };

  onModalDataChanged = (data) => {
    if (this.visible === data.visible) return;
    this.visible = data.visible;

    if (this.visible) {
      this.alpha = 1;
      if (this.container) this.container.scale.set(this.container.displayData.scaleX);
      this.onShowed(data);
    } else {
      this.close();
    }
  };

  onCloseClicked = () => {
    store.dispatch(actionsModals.closeModal({ type: this.type }));
    this.onClosed();
  };

  hide() {
    this.container = this.getChildByName('container');
    if (this.container) {
      this.container.centring();

      TweenMax.to(this.container.scale, 0.2, { x: 0, y: 0 });
    }

    TweenMax.to(this, 0.2, { alpha: 0, onComplete: this.close.bind(this) });
  }

  fade() {
    TweenMax.to(this, 0.3, { alpha: 0, onComplete: this.close.bind(this) });
  }

  close() {
    if (this.visible) {
      store.dispatch(actionsModals.closeModal({ type: this.type }));
    }
    this.onClosed();
  }
}
