import Event from '../utils/event';

export default class StoreObserver {
  constructor(store) {
    this.store = store;
    this.debugEnabled = false;
    this.store.subscribe(this.onStoreChanged);
    this.prevState = null;
    this.currentState = store.getState();
    this.selectors = {};
  }
// here is a bug when first event listener update redux second same event listener wouldn't see diff between prevValue & newValue
//priority is a quick hack to call listeners before those who would update state

  onStoreChanged = () => {
    this.prevState = this.currentState;
    this.currentState = this.store.getState();

    const keys = Object.keys(this.selectors).sort((key1, key2) => this.selectors[key2].priority - this.selectors[key1].priority);
    for (let key of keys) {
      let item = this.selectors[key];
      const prevValue = item.check(this.prevState);
      const newValue = item.check(this.currentState);
      if (this.debugEnabled)
        console.log('Store observer\n' +
          `prevValue: ${prevValue}\n` +
          `newValue: ${newValue}\n` +
          `Not same link: ${prevValue !== newValue}\n` +
          `Not same content: ${(JSON.stringify(prevValue) !== JSON.stringify(newValue))}\n` +
          `Events count: ${item.events._listeners.length}`);
      if (prevValue !== newValue) {
        item.events.call(newValue);
      }
    }
  };

  addListener = (check, callback, priority = 0) => {
    if (!this.selectors[check]) {
      this.selectors[check] = { check: check, events: new Event(), priority };
    }
    this.selectors[check].events.add(callback);
  };

  addListenerAsNew = (check, callback, priority = 0) => {
    let newKey = check.toString();
    if (this.selectors[check]) {
      newKey += Math.random();
    }
    this.selectors[newKey] = { check: check, events: new Event(), priority };
    this.selectors[newKey].events.add(callback);
  };

  addListenerAndGetValue = (check, callback) => {
    this.addListener(check, callback);

    return check(this.store.getState());
  };

  addListenerAsNewAndGetValue = (check, callback) => {
    this.addListenerAsNew(check, callback);

    return check(this.store.getState());
  };

  removeListener = (check, callback) => {
    if (!this.selectors[check]) {
      return;
    }

    this.selectors[check].events.remove(callback);
  }

  useSelector = (selector) => {
    return selector(this.store.getState());
  }
}
