import ModalBase from '../base/modalBase';
import eModalType from './eModalType';
import selectors from '../../redux/game/selectors';
import { store, storeObserver } from '../../index';
import WinAmountCounter from '../winAmountCounter';
import { winAmountCounterStyle } from '../../fontStyles';
import ControllerSounds, { eSoundType } from '../sounds/controllerSounds';
import { eGameState } from '../../redux/game/reducer';

export default class ModalWin extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);
    this.type = eModalType.EMT_WIN;
    this.winCounter = this.getChildByName('winCounter');
    this.init();
  }

  init() {
    this.initListener();
    this.winCounterStyle = {
      ...winAmountCounterStyle,
      fontSize: 180,
    };
    this.interactive = true;
    // document.addEventListener('keyup', event => this.onKeyDown(event), false);
    storeObserver.addListener(selectors.getGameState, this.onGameStateChange);
    this.visible = false;
  }

  onShowed = (modalData) => {
    ControllerSounds.playSound(eSoundType.EST_WIN);
    ControllerSounds.playSound(eSoundType.EST_COINS);
    this.setAmount(modalData.data);
  };

  setAmount = (value) => {
    if (!this.winAmountCounter) {
      this.winAmountCounter = new WinAmountCounter(this.winCounter, this.winCounterStyle);

      this.winAmountCounter.onStop = () => {
        this.showed = false;
        this.close();
      };
    }

    if (this.showed) return;
    this.showed = true;

    const currencyInfo = selectors.getCurrencyInfo(store.getState());
    const decimals = Math.min(value.getPrecision(), currencyInfo.decimal);

    this.winAmountCounter.animate(value, decimals, 0.2, 0.7);
  }

  onKeyDown(event) {
    if (event.keyCode === 32 && this.visible) { //32 - Space
      this.showed = false;
      this.stop();
      this.winAmountCounter.stop();
    }
  }

  onGameStateChange = (gameState) => {
    if (this.gameState === gameState) return;
    this.gameState = gameState;
    const state = store.getState().game;
    if (gameState === eGameState.EGS_WAITING_FOR_RESPONSE && !state.autospin) {
      if (this.winAmountCounter) {
        this.winAmountCounter.stop();
      } else {
        this.showed = false;
        this.close();
      }
    }
  }
}
