export default {
  ETWS_INFO: 'info',
  ETWS_MORE: 'more',
  ETWS_NOTIFICATION: 'notification',
}

export const eNotificationTypes = {
  BONUSES: 'bonuses',
  STATUS_CHANGE: 'statusChanged',
  TOURNAMENT_START: 'tournamentStart',
  TOURNAMENT_END: 'tournamentEnds',
};
