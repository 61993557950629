import eModalType from './eModalType';
import ModalBase from '../base/modalBase';
import ControllerSounds from '../sounds/controllerSounds';
import { store } from '../../index';
import actions from '../../redux/game/actions';
import { eScreenMode } from '../../enums';

export default class ModalSettings extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);

    this.type = eModalType.EMT_SETTINGS;

    this.initListener();
    // this.initBlocker();
    this.getChildByName('buttonClose').addListener('pointertap', this.onCloseClicked);

    this.switchSounds = this.getChildByName('switchSounds');
    this.switchSounds.addEventListenerSwitched(this.onSoundsSwitched);
    this.switchSounds.setEnabled(!ControllerSounds.soundsMuted);

    this.switchMusic = this.getChildByName('switchMusic');
    this.switchMusic.addEventListenerSwitched(this.onMusicSwitched);
    this.switchMusic.setEnabled(!ControllerSounds.musicMuted);

    if (PIXI.utils.isMobile.any) {
      this.background = this.getChildByName('bg');
      this.portretBackground = this.getChildByName('bg_portret');
      this.portretBackground.children[1].alpha = 0.8;
    }
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize = (data) => {
    if (data.isMobile && data.isLandscape) {
      this.background.visible = true;
      this.portretBackground.visible = false;
    } else if (data.isMobile && data.isPortrait) {
      this.background.visible = false;
      this.portretBackground.visible = true;
    }
  };

  onShowed = () => {
    store.dispatch(actions.setScreenMode(eScreenMode.SETTINGS));
    this.switchSounds.setEnabled(!ControllerSounds.soundsMuted);
    this.switchMusic.setEnabled(!ControllerSounds.musicMuted);
  };

  onClosed = () => {
    store.dispatch(actions.setScreenMode(eScreenMode.GAME));
  };

  onSoundsSwitched = (value) => {
    ControllerSounds.setSoundsMuted(!value);
  };

  onMusicSwitched = (value) => {
    if (ControllerSounds.musicMuted) {
      ControllerSounds.unmuteSFX();
    } else {
      ControllerSounds.muteSFX();
    }
  };
}
