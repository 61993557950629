import { createActions } from 'redux-actions';

export default createActions(
  {
    SHOW_MODAL: (type) => type,
    CLOSE_MODAL: (type) => type,
  },
  {
    prefix: 'modals',
    namespace: '.',
  },
);
