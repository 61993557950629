import ModalBase from '../base/modalBase';
import eModalType from './eModalType';
import {
  winAmountCounterStyle,
  yellowStrokeGradient,
} from '../../fontStyles';
import { store } from '../../index';
import WinAmountCounter from '../winAmountCounter';
import actionsModals from '../../redux/modals/actions';
import ControllerSounds, { eSoundType } from '../sounds/controllerSounds';

export default class ModalFreeSpins extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;
    this.type = eModalType.EMT_WIN_FREE_SPINS;

    // setTimeout(this.onModalDataChanged.bind(this, { visible: true }), 2000);

    this.init();
  }

  init() {
    this.container = this.getChildByName('container');
    this.initListener();
    this.setTextStyle();
    // this.on('pointertap', this.onClick);
  }

  destroy() {
    super.destroy();
    this.off('pointertap', this.onClick);
  }

  setAmount(value) {
    this.winAmountCounter = new WinAmountCounter(this.container.getChildByName('winCounter'), {
      ...winAmountCounterStyle,
      fontName: 'BoxerCountFont',
      fontSize: 120
    });
    this.winAmountCounter.animate(value, 0, 3);
    this.winAmountCounter.onStop = this.hide.bind(this);
  }

  showBlackout() {
    const blackout = this.getChildByName('blackout');
    if (!blackout) return;
    blackout.show(true, 0.3);
  }

  setTextStyle() {
    const youWinText = this.container.getChildByName('popup_freespins_you_won');
    const freeSPinsText = this.container.getChildByName('popup_freespins_free_spins');

    youWinText.style = { ...youWinText.style, ...yellowStrokeGradient };
    freeSPinsText.style = { ...freeSPinsText.style, ...yellowStrokeGradient };
  }

  onClick() {
    store.dispatch(actionsModals.closeModal({ type: this.type }));
  }

  show(amount) {
    this.showBlackout();
    ControllerSounds.playSound(eSoundType.EST_MEGA_WIN);
    this.setAmount(amount);
  }

  onShowed = (data) => {
    const state = store.getState().game;
    this.show(state.freeSpinsMax);
  };
}
