import { shuffle } from '../utils/array';

class ControllerMock {
  constructor() {
    this.normalSymbols = [
      ['2', '3', '4', '5', '6', '7', '8'],
      ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '3', '4', '5', '6', '7', '8'],
      ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '3', '4', '5', '6', '7', '8'],
      ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '3', '4', '5', '6', '7', '8'],
      ['2', '3', '4', '5', '6', '7', '8', '3', '4', '5', '6', '7', '8'],
      ['2', '3', '4', '5', '6', '7', '8', '9'],
      ['2', '3', '4', '5', '6', '7', '8', '9'],
    ];

    this.freespinSymbols = [
      ['6', '7', '8', '9', 'B'],
      ['6', '7', '8', '9', 'B', 'W'],
      ['6', '7', '8', '9', 'B', 'W', 'S'],
      ['6', '7', '8', '9', 'B', 'W', 'S'],
      ['6', '7', '8', '9', 'B', 'W', 'S'],
      ['6', '7', '8', '9', 'B', 'W'],
      ['6', '7', '8', '9', 'B', 'W'],
    ];

    for (let i = 0; i < this.normalSymbols.length; i++) {
      this.normalSymbols[i] = shuffle(this.normalSymbols[i]);
    }

    for (let i = 0; i < this.freespinSymbols.length; i++) {
      this.freespinSymbols[i] = shuffle(this.freespinSymbols[i]);
    }
    this.availableSymbols = this.normalSymbols;

    // this.availableSymbols = [
    //   ['1', '1', '1'],
    //   ['1', '1', 'W'],
    //   ['1', '1', '1'],
    //   ['B', 'B', 'B'],
    //   ['B', 'B', 'W'],
    //   [],
    //   []
    // ];
    //

    // this.freespinSymbols = [
    //   ['4', '4', '4'],
    //   ['4', '4', '4'],
    //   ['W', 'W', 'W'],
    //   ['W', 'W', 'W'],
    //   ['S', 'S', 'S'],
    //   ['S', 'S', 'S'],
    //   ['1', '1', '1'],
    // ];
    // this.availableSymbols = [
    //   ['S', 'S', '1'],
    //   ['S', '1', 'W'],
    //   ['S', 'S', 'S'],
    //   ['B', 'B', 'B'],
    //   ['B', 'B', 'W'],
    // ];

    // this.availableSymbols = [
    //   ['7', '8', '9', '9'],
    //   ['6', '7', '8', '9'],
    //   ['7', '8', '9', '9'],
    //   ['6', '7', '8', '9'],
    //   ['7', '8', 'W', '9'],
    //   [],
    //   []
    // ];

    // this.availableSymbols = [
    //   ['7', '7', '7'],
    //   ['1', '7', '7'],
    //   ['W', 'W', 'W'],
    //   ['8', '8', '8'],
    //   ['8', 'W', '8'],
    // ];

    this.availableCoefs = {
      W: [0, 0, 0, 0, 0, 0, 0],
      B: [0, 8, 18, 180, 1800, 3000, 6000],
      S: [0, 2, 30, 250, 2000, 3500, 7000],
      9: [0, 0, 20, 200, 800, 1000, 2000],
      8: [0, 0, 10, 175, 500, 900, 1500],
      7: [0, 0, 10, 150, 375, 700, 1400],
      6: [0, 0, 5, 125, 250, 500, 1000],
      5: [0, 0, 5, 100, 200],
      4: [0, 0, 3, 35, 150],
      3: [0, 0, 3, 30, 125],
      2: [0, 0, 2, 4, 50],
      1: [0, 0, 2, 5, 25]
    };

    this.balance = 5000;
    this.frespinsCount = 0;
    this.spinsCount = 0;
  }

  requestConfig = async () => {
    return {
      bet_amount: 10,
      available_icons: JSON.parse(JSON.stringify(this.availableSymbols)),
      normalSymbols: JSON.parse(JSON.stringify(this.normalSymbols)),
      freespinSymbols: JSON.parse(JSON.stringify(this.freespinSymbols)),
      paytable: this.availableCoefs,
      recover: false,
      currency: 'DEMO',
      balance: this.balance,
      statistic: '',
      steps: -1,
      ticketId: '',
      bet_min: 10,
      bet_max: 1000,
      decimal: 0,
    };
  };

  requestNewGame = async (amount) => {
    debugger;
    if (this.balance - amount < 0) {
      return {
        error: true,
        message: 'You don`t have enough funds.'
      };
    }

    const reel = [];
    for (let i = 0; i < this.availableSymbols.length; i++) {
      const avSy = this.availableSymbols[i];
      reel.push([avSy[Math.round(Math.random() * (avSy.length - 1))]]);
    }
    this.spinsCount++;
    if (this.spinsCount > 19) {
      this.spinsCount = 0;
      reel[3] = ['S'];
      reel[4] = ['S'];
    }
    const lineInfo = this.getLineInfo(reel);
    let wt = amount * lineInfo.coef;
    if (this.freespinsEnabled) {
      this.frespinsCount--;
      this.spinsCount = 0;
    } else {
      this.balance -= amount;
    }
    this.balance += wt;

    if (lineInfo.scatterCount > 1) {
      this.frespinsCount += 5 * lineInfo.scatterCount;
      this.freespinsEnabled = true;
      this.availableSymbols = this.freespinSymbols;
    }

    if (this.frespinsCount === 0) {
      this.freespinsEnabled = false;
    }

    const response = {
      balance: this.balance,
      spin: {
        rl: reel,
        wl: lineInfo.wl,
        wt
      },
      freespinsEnabled: this.freespinsEnabled,
      freespinsCount: this.frespinsCount,
      available_icons: JSON.parse(JSON.stringify(this.availableSymbols)),
    };

    if (this.frespinsCount === 0) {
      this.availableSymbols = this.normalSymbols;
    }

    return response;
  };

  getLineInfo(line) {
    const result = { wl: [], symbol: '', coef: 0, scatterCount: 0, coefs: [] };
    for (let i = 0; i < line.length; i++) {
      const symbol = line[i][0];
      result.symbol = symbol;
      const winLine = [i];
      for (let k = i - 1; k >= 0; k--) {
        if (line[k][0] !== 'W') break;
        winLine.push(k);
      }
      let j = i + 1;
      for (j; j < line.length; j++) {
        if (line[j][0] !== symbol && line[j][0] !== 'W') break;
        winLine.push(j);
      }

      let coef = this.availableCoefs[result.symbol][winLine.length - 1];
      if (coef !== 0) {
        this.checkWinLine(winLine, coef, result);
        i = j - 1;
      }
    }

    for (let symbol of line) {
      if (symbol[0] === 'S') result.scatterCount++;
    }

    for (let coef of result.coefs) {
      result.coef += coef;
    }

    return result;
  }

  checkWinLine(winLine, coef, result) {
    let hasSameIndexes = false;
    for (let i = 0; i < winLine.length - 1; i++) {
      const index = winLine[i];
      if (result.wl.length > 0 && result.wl[result.wl.length - 1].includes(index)) {
        hasSameIndexes = true;
        break;
      }
    }
    if (hasSameIndexes) {
      if (result.coefs[result.coefs.length - 1] < coef) {
        result.coefs[result.coefs.length - 1] = coef;
        result.wl[result.wl.length - 1] = winLine;
      } else {
        winLine.shift();
        let coef = this.availableCoefs[result.symbol][winLine.length - 1];
        if (coef !== 0) this.checkWinLine(winLine, coef, result);
      }
    } else {
      result.wl.push(winLine);
      result.coefs.push(coef);
    }
  }
}

export default new ControllerMock();
