import { store } from '../../../index';
import selectors from '../../../redux/game/selectors';
import { sleep } from '../../../utils/sleep';
import actionsModals from '../../../redux/modals/actions';
import actions from '../../../redux/game/actions';
import { eGameState } from '../../../redux/game/reducer';
import { requestNewGame } from '../../../api/rest';
import eModalType from '../../popups/eModalType';
import controllerSounds, { eSoundType } from '../../sounds/controllerSounds';
import ControllerBaseBehaviour from './controllerBaseBehaviour';

export default class ControllerSlotStartFreespinsBehaviour extends ControllerBaseBehaviour{
  constructor(slotController) {
    super(slotController);
    this._slotController = slotController;
  }

  endSpin() {
    const state = store.getState();
    const winLines = selectors.getWinLine(state);

    this._slotController.startWinAnimation(winLines, true).then(async ({ delay, isWin }) => {
      controllerSounds.playSound(eSoundType.EST_SCATTER);
      await sleep(delay);
      this._slotController.setStartFrameAnimation();
      store.dispatch(actionsModals.showModal({ type: eModalType.EMT_WIN_FREE_SPINS }));
      await sleep(5000);

      this.updateFreeBets();
      store.dispatch(actions.setGameState(eGameState.EGS_WAITING_FOR_RESPONSE));
      await requestNewGame();
    });
  }
}
