import { store } from '../../../index';
import actions from '../../../redux/game/actions';
import { eGameState } from '../../../redux/game/reducer';

export default class ControllerBaseBehaviour {
  constructor() {
    this.freeBetsController = window.OPWrapperService.freeBetsController;
  }
  updateFreeBets() {
    const state = store.getState().game;
    this.freeBetsController.updateTotalWin();
    if (!state.freespinsEnabled && !state.bonusGameShowed) {
      this.freeBetsController.show();
      window.OPWrapperService.realityCheck.blockedRealityCheck = false;
    }
    if (
      (this.freeBetsController.isFirstFreeBet || this.freeBetsController.isLastFreeBet || window.OPWrapperService.realityCheck.blockedGame)
      && state.autospin
    ) {
      store.dispatch(actions.stopAutospin());
      store.dispatch(actions.setGameState(eGameState.EGS_STOP_AUTOSPIN));
    }
  }
}
