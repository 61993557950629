import ModalBase from '../../base/modalBase';
import eModalType from '../eModalType';
import { TweenMax } from 'gsap';
import { whiteBeigeGradient } from '../../../fontStyles';
import actions from '../../../redux/game/actions';
import { store } from '../../../index';
import animationsLoader from '../../animations/animationsLoader';
import eAnimationType from '../../animations/eAnimationType';
import ePopupAnimation from '../ePopupAnimation';
import selectors from '../../../redux/game/selectors';
import controllerSounds, { eSoundType } from '../../sounds/controllerSounds';

export default class ModalNextOpponent extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;
    this.type = eModalType.EMT_NEXT_OPPONENT;

    // setTimeout(this.onModalDataChanged.bind(this, { visible: true }), 2000);

    this.init();
  }

  init() {
    this.panel = this.getChildByName('panel');
    this.ringGirlAnimationContainer = this.getChildByName('ring_girl');
    this.panelAnimationContainer = this.panel.getChildByName('panel_animation');
    this.panelTextContainer = this.panel.getChildByName('text_container');
    this.expText = this.panelTextContainer.getChildByName('exp');
    this.title = this.panelTextContainer.getChildByName('next_opponent_title');
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
    this.initAnimations();
    this.setTextStyle();
    this.initListener();
  }

  onResize = (data) => {
    if (data.isMobile && data.isLandscape) {
      this.panelAnimation.scale.set(1);
      this.panelAnimation.y = 0;
    } else if (data.isMobile && data.isPortrait) {
      this.panelAnimation.scale.set(1.1);
      this.panelAnimation.y = 50;
    }
  }

  initAnimations = () => {
    this.panelAnimation = animationsLoader.createAnimation(eAnimationType.EAT_POPUPS);
    this.panelAnimationContainer.addChild(this.panelAnimation);
    this.panelAnimation.visible = false;
  }

  setTextStyle = () => {
    this.expText.style = { ...this.expText.style, ...whiteBeigeGradient };
  }

  destroy() {
    super.destroy();
    this.off('pointertap', this.onClick);
  }

  onClick = () => {
    this.hide();
  }

  show() {
    controllerSounds.playSound(eSoundType.EST_RING_BELL, 0.7);
    controllerSounds.playSound(eSoundType.EST_NEXT_OPPONENT_OVATION);
    this.showPanel();
    setTimeout(this.hide.bind(this), 4000);
  }

  showPanel = () => {
    this.panelAnimation.visible = true;
    this.panelAnimation.state.setAnimation(0, ePopupAnimation.EPA_NEXT_OPPONENT_START, false);
    this.panelAnimation.state.addAnimation(0, ePopupAnimation.EPA_NEXT_OPPONENT_IDLE, false, 0);
    const state = store.getState();
    const myHealth = selectors.getMyHeals(state);
    this.title.text = myHealth === 0 ? window.OPWrapperService.localizations.getLocalizedText('popup_next_opponent_you_defeated') : window.OPWrapperService.localizations.getLocalizedText('popup_next_opponent');
    const differenceExp = selectors.getDifferenceExp(state) || 0;
    this.expText.text = `+ ${differenceExp} XP`;
    TweenMax.to(this.panelTextContainer, 1, { alpha: 1 });
  }

  updateRealCurrentEnemy = () => {
    store.dispatch(actions.updateRealCurrentEnemy());
  }

  hide() {
    this.updateRealCurrentEnemy();
    controllerSounds.playSound(eSoundType.EST_RING_BELL, 0.7);
    this.panelAnimation.state.setAnimation(0, ePopupAnimation.EPA_NEXT_OPPONENT_END, false);
    TweenMax.to(this.panelTextContainer, 1, { alpha: 0, onComplete: () =>{
      this.panelAnimation.visible = false;
      this.close();
    } });
  }

  onShowed = () => {
    this.show();
  };
}

