import ModalBase from '../base/modalBase';
import eModalType from './eModalType';
import eSymbolType from '../symbol/eSymbolType';
import selectors from '../../redux/game/selectors';
import { store } from '../../index';
import actions from '../../redux/game/actions';
import { eScreenMode } from '../../enums';

export default class ModalPaytable extends ModalBase {

  constructor(data, displayData) {
    super(data, displayData);

    this.type = eModalType.EMT_PAYTABLE;
    this.initListener();
    // this.initBlocker();
    this.interactive = true;

    this.slides = [];
    this.currentSlide = 0;
    this.slideContainer = this.getChildByName('slide_container');
    for (let i = 0; i < 4; i++) {
      const slide = this.slideContainer.getChildByName(`slide_${i}`);
      slide.visible = i === this.currentSlide;
      this.slides.push(slide);
    }

    this.paytable = selectors.getPaytable(store.getState());
    this.initSlide2();
    this.initSlide3();

    const buttons = this.getChildByName('buttons');
    buttons.getChildByName('buttonClose').addListener('pointertap', this.onCloseClicked);
    buttons.getChildByName('buttonPrev').addListener('pointertap', this.onPrevClicked);
    buttons.getChildByName('buttonNext').addListener('pointertap', this.onNextClicked);
    if (PIXI.utils.isMobile.any) {
      this.background = this.getChildByName('bg');
      this.portretBackground = this.getChildByName('bg_portret');
      this.portretBackground.children[1].alpha = 0.8;
    }
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize = (data) => {
    if (data.isMobile && data.isLandscape) {
      this.background.visible = true;
      this.portretBackground.visible = false;
    } else if (data.isMobile && data.isPortrait) {
      this.background.visible = false;
      this.portretBackground.visible = true;
    }
  };

  onShowed = () => {
    store.dispatch(actions.setScreenMode(eScreenMode.PAYTABLE));
  };

  onClosed = () => {
    store.dispatch(actions.setScreenMode(eScreenMode.GAME));
  };

  initSlide2() {
    const symbols = [eSymbolType.EST_HIGH_0, eSymbolType.EST_HIGH_1, eSymbolType.EST_HIGH_2];
    this.initCoefsLabels(this.slides[2], symbols);
  }

  initSlide3() {
    const symbols = [
      eSymbolType.EST_LOW_0,
      eSymbolType.EST_LOW_1,
      eSymbolType.EST_LOW_2,
      eSymbolType.EST_LOW_3,
    ];

    this.initCoefsLabels(this.slides[3], symbols);
  }

  initCoefsLabels(slide, symbols) {
    for (let type of symbols) {
      const item = slide.getChildByName(`coefs_view_${type}`);
      for (let i = 3; i < 6; i++) {
        let textPair = item.getChildByName(`coef_view_${i - 3}`);
        textPair.getChildByName('viewCount').text = i;
        textPair.getChildByName('viewCoef').text = `×${this.paytable[type][i - 3]}`;
      }
    }
  }

  onPrevClicked = () => {
    this.slides[this.currentSlide].visible = false;
    this.currentSlide = (4 + this.currentSlide - 1) % 4;
    this.slides[this.currentSlide].visible = true;
  };

  onNextClicked = () => {
    this.slides[this.currentSlide].visible = false;
    this.currentSlide = (this.currentSlide + 1) % 4;
    this.slides[this.currentSlide].visible = true;
  };
}
