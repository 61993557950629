import selectors from '../../../redux/game/selectors';
import { store } from '../../../index';
import actions from '../../../redux/game/actions';
import { eGameState } from '../../../redux/game/reducer';
import { sleep } from '../../../utils/sleep';
import actionsModals from '../../../redux/modals/actions';
import eModalType from '../../popups/eModalType';
import constants from '../../../constants';
import ControllerBaseBehaviour from './controllerBaseBehaviour';

export default class ControllerSlotLastFreespinBehaviour extends ControllerBaseBehaviour{

  constructor(slotController) {
    super(slotController);
    this._slotController = slotController;
  }

  endSpin() {
    const state = store.getState();
    const winLines = selectors.getWinLine(state);
    const betAmount = selectors.getBetAmount(state);
    const winAmount = selectors.getWinAmount(state);
    this._slotController.startWinAnimation(winLines, true).then(async ({ delay, isWin }) => {
      if (isWin) this._slotController.setIdleFrameAnimation();

      await sleep(delay - 1000);

      store.dispatch(actions.setGameState(eGameState.EGS_SHOW_WIN));

      if (winAmount > 0 && winAmount >= constants.MULTIPLIER_BIG_WIN * betAmount) {
        await sleep(7000);

      } else {
        await sleep(500);

      }

      store.dispatch(actionsModals.showModal({ type: eModalType.EMT_TOTAL_WIN }));

      await sleep(4700);

      this._slotController.closeFrame();
      // await sleep(time);

      this.updateFreeBets();
      store.dispatch(actions.setGameState(eGameState.EGS_READY_TO_PLAY));
    });
  }

}
