export const coin0Config = {
  x0min: 650, // px
  x0max: 750, // px
  y0min: 575, // px
  y0max: 575, // px
  scaleStart: 0.5,
  scaleEnd: 1,
  angle0From: -2.0944,
  angle0To: -Math.PI / 2,
  angleVelocityMin: 0.005,
  angleVelocityMax: 0.006,
  v0min: 2, // px / milliseconds
  v0max: 2.5, // px / milliseconds
  gravityX: 0,
  gravityY: 1.2,
  mass: 0.06, // ~kg
  drag: 1 / 90,
  lifetimeMin: 2000, // milliseconds
  lifetimeMax: 4000, // milliseconds
  сount: 15,
  box: {
    x: 0,
    y: 0,
    height: 1000
  },
  leftBound: false,
  topBound: false,
  rightBound:false,
  bottomBound: false,
};
export const coin1Config = {
  x0min: 1550, // px
  x0max: 1650, // px
  y0min: 575, // px
  y0max: 575, // px
  scaleStart: 0.5,
  scaleEnd: 1,
  angle0From: -Math.PI / 2,
  angle0To: -1.0472,
  angleVelocityMin: 0.005,
  angleVelocityMax: 0.006,
  v0min: 2, // px / milliseconds
  v0max: 2.5, // px / milliseconds
  gravityX: 0,
  gravityY: 1.2,
  mass: 0.06, // ~kg
  drag: 1 / 90,
  lifetimeMin: 2000, // milliseconds
  lifetimeMax: 4000, // milliseconds
  сount: 15,
  box: {
    x: 0,
    y: 0,
    height: 1000
  },
  leftBound: false,
  topBound: false,
  rightBound:false,
  bottomBound: false,
};
// export const coinConfig = {
//   x0min: 1100, // px
//   x0max: 1200, // px
//   y0min: 650, // px
//   y0max: 750, // px
//   scaleStart: 0.5,
//   scaleEnd: 1,
//   angle0From: -2.0944,
//   angle0To: -1.0472,
//   angleVelocityMin: 0.005,
//   angleVelocityMax: 0.006,
//   v0min: 2.5, // px / milliseconds
//   v0max: 3, // px / milliseconds
//   gravityX: 0,
//   gravityY: 1.2,
//   mass: 0.06, // ~kg
//   drag: 1 / 90,
//   lifetimeMin: 1000, // milliseconds
//   lifetimeMax: 2000, // milliseconds
//   сount: 25,
//   box: {
//     x: 0,
//     y: 0,
//     height: 1000
//   },
//   leftBound: false,
//   topBound: false,
//   rightBound:false,
//   bottomBound: true,
// };

// export const coinConfig = {
//   x0min: 1100, // px
//   x0max: 1200, // px
//   y0min: 650, // px
//   y0max: 750, // px
//   scaleStart: 0.2,
//   scaleEnd: 0.5,
//   angle0From: -2.0944,
//   angle0To: -1.0472,
//   angleVelocityMin: 0.005,
//   angleVelocityMax: 0.006,
//   v0min: 2.5, // px / milliseconds
//   v0max: 3, // px / milliseconds
//   gravityX: 0,
//   gravityY: 1.2,
//   mass: 0.06, // ~kg
//   drag: 1 / 90,
//   lifetimeMin: 1000, // milliseconds
//   lifetimeMax: 2000, // milliseconds
//   сount: 25,
//   box: {
//     x: 0,
//     y: 0,
//     height: 1000
//   },
//   leftBound: false,
//   topBound: false,
//   rightBound:false,
//   bottomBound: true,
// };

// export const coinConfig = {
//   x0min: 600, // px
//   x0max: 1400, // px
//   y0min: -50, // px
//   y0max: 100, // px
//   scaleStart: 0.1,
//   scaleEnd: 0.15,
//   angle0From: Math.PI/2 - Math.PI/6,
//   angle0To: Math.PI/2 + Math.PI/6,
//   angleVelocityMin: 0.005,
//   angleVelocityMax: 0.006,
//   v0min: 1, // px / milliseconds
//   v0max: 1, // px / milliseconds
//   gravityX: 0.7,
//   gravityY: 1.2,
//   mass: 0.01, // ~kg
//   drag: 1 / 20,
//   lifetimeMin: 1000, // milliseconds
//   lifetimeMax: 2000, // milliseconds
//   сount: 25,
//   box: {
//     x: 0,
//     y: 0,
//     height: 1000
//   },
//   leftBound: false,
//   topBound: false,
//   rightBound:false,
//   bottomBound: true,
// };
