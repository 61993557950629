import ModalBase from '../../base/modalBase';
import eModalType from './../eModalType';
import WinAmountCounter from '../../winAmountCounter';
import { store } from '../../../index';
import { winAmountCounterStyle } from '../../../fontStyles';
import ControllerSounds, { eSoundType } from '../../sounds/controllerSounds';
import selectors from '../../../redux/game/selectors';
import animationsLoader from '../../animations/animationsLoader';
import eAnimationType from '../../animations/eAnimationType';
import ePopupAnimation from './../ePopupAnimation';
import ParticleSystem from '../../../utils/particleSystem';
import { coin0Config, coin1Config } from './particleConfigsBigWins';
import constants from '../../../constants';

// TODO: Need rename class, like AmountModal or something
export default class ModalBigWin extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;
    this.type = eModalType.EMT_WIN_BIG;
    this.init();

    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize = (data) => {
    if (data.isMobile && data.isLandscape) {
      this.container.centring();
      this.normalScale = 1;
      this.container.scale.set(this.normalScale);

    } else if (data.isMobile && data.isPortrait) {
      this.container.centring();
      this.normalScale = 0.6;
      this.container.scale.set(this.normalScale);

    }
  }

  init() {
    this.container = this.getChildByName('container');
    this.animation = animationsLoader.createAnimation(
      eAnimationType.EAT_POPUPS
    );
    this.animation.x = this.container.displayData.width / 2;
    this.animation.y = this.container.displayData.height / 2;
    this.animation.visible = false;
    this.container.addChildAt(this.animation, 0);
    this.winAmountCounter = new WinAmountCounter(
      this.container.getChildByName('winCounter'),
      winAmountCounterStyle
    );
    this.initListener();
    // this.on('pointertap', this.onClick);
    // document.addEventListener(
    //   'keydown',
    //   (event) => this.onKeyDown(event),
    //   false
    // );
    this.normalScale = 1;

  }

  destroy() {
    super.destroy();
    this.off('pointertap', this.onClick);
  }

  onShowed = () => {
    const state = store.getState();
    const winBig = state.modals.winBig;
    const betAmount = state.game.betAmount;
    const winAmount = winBig.data;
    let animationType = 'YOU_WON';

    // if (!winBig.visible) {
    //   this.animation.visible = false;
    //   return;
    // }
    this.container.scale.set(this.normalScale);
    this.alpha = 1;
    ControllerSounds.playSound(eSoundType.EST_MEGA_WIN);

    this.coins0 = new ParticleSystem(this.container, { animationType: eAnimationType.EAT_COINS, animationName: 'coin' }, coin0Config);
    this.coins1 = new ParticleSystem(this.container, { animationType: eAnimationType.EAT_COINS, animationName: 'coin' }, coin1Config);

    if (winAmount >= betAmount * constants.MULTIPLIER_BIG_WIN)
      animationType = 'BIG_WIN';
    if (winAmount >= betAmount * constants.MULTIPLIER_MEGA_WIN)
      animationType = 'MEGA_WIN';

    this.setAmount(animationType, winAmount);
  }

  setAmount = (animationType, value) => {
    this.animation.visible = true;
    this.animation.state.setAnimation(
      0,
      ePopupAnimation[`EPA_${animationType}_START`],
      false
    );
    this.animation.state.addAnimation(
      0,
      ePopupAnimation[`EPA_${animationType}_IDLE`],
      true,
      1
    );

    this.showBlackout();

    const currencyInfo = selectors.getCurrencyInfo(store.getState());
    const decimals = Math.min(value.getPrecision(), currencyInfo.decimal);

    this.winAmountCounter.animate(value, decimals, 5, 2);
    this.winAmountCounter.onStop = () => {
      this.hide();
    };
  };

  showBlackout() {
    const blackout = this.getChildByName('blackout');
    if (!blackout) return;
    blackout.show(true, 0.3);
  }

  onKeyDown(event) {
    if (event.keyCode === 32 && this.visible) { //32 - Space
      this.hide();
      this.winAmountCounter.stop();
    }
  }

  onClick() {
    this.hide();
    this.winAmountCounter.stop();
  }

  close() {
    this.coins0.stop();
    this.coins1.stop();
    super.close();
  }
}
