export default {
  0: 1,
  6: 2,
  12: 3,
  3: 4,

  1: 6,
  9: 7,
  7: 8,
  13: 9,

  2: 11,
  15: 12,
  4: 13,
  10: 14,

  8: 16,
  14: 17,
  5: 18,
  11: 19,

};
