import { getFromStorage, setToStorage } from '../../utils/localStorage';
import SoundManager from './SoundManager';
import Event from '../../utils/event';
import { TweenMax } from 'gsap';

export const eSoundType = {
  EST_MEGA_WIN: 'megaWin',
  EST_CLICK: 'click',
  EST_COINS: 'coins',
  EST_SFX: 'sfx',
  EST_WIN: 'win',
  EST_REEL_STOP: 'reelStop',
  EST_BOXER_IDLE_0: 'boxer_idle_0',
  EST_BOXER_IDLE_1: 'boxer_idle_1',
  EST_BOXER_WIN_MISHIT_0: 'boxer_win_mishit_0',
  EST_BOXER_WIN_MISHIT_1: 'boxer_win_mishit_1',
  EST_BOXER_WIN_MISHIT_2: 'boxer_win_mishit_2',
  EST_BOXER_WIN_MISHIT_3: 'boxer_win_mishit_3',
  EST_BOXER_WIN_LOW_0: 'boxer_win_low_0',
  EST_BOXER_WIN_LOW_1: 'boxer_win_low_1',
  EST_BOXER_WIN_LOW_2: 'boxer_win_low_2',
  EST_BOXER_BIG_WIN: 'boxer_big_win',
  EST_BOXER_MEGA_WIN: 'boxer_mega_win',
  EST_NOISE_HALL: 'hallNoise',
  EST_SCATTER: 'scatter',
  EST_SELECT_MULTIPLIER: 'selectMultiplier',
  EST_WIN_MULTIPLIER: 'winMultiplier',
  EST_TOTAL_WIN: 'totalWin',
  EST_SFX_BONUS_GAME: 'sfxBonusGame',
  EST_BONUS_BOXER_IDLE_2: 'bonus_boxer_idle_2',
  EST_BONUS_BOXER_WIN_0: 'bonus_boxer_win_0',
  EST_BONUS_BOXER_WIN_1: 'bonus_boxer_win_1',
  EST_BONUS_BOXER_START_0: 'bonus_boxer_start_0',
  EST_BONUS_BOXER_START_1: 'bonus_boxer_start_1',
  EST_RING_BELL: 'ring_bell',
  EST_NEXT_OPPONENT_OVATION: 'next_opponent_ovation',
  EST_HEY: 'hey',
};

class ControllerSounds {

  constructor() {
    this.SFXVolume = 0.6;
    this.aliasSFX = eSoundType.EST_SFX;
    this.musicMuted = getFromStorage('musicMuted');
    if (this.musicMuted === null) {
      setToStorage('musicMuted', 'false');
      this.musicMuted = false;
    }

    this.soundsMuted = getFromStorage('soundsMuted');
    if (this.soundsMuted === null) {
      setToStorage('soundsMuted', 'false');
      this.soundsMuted = false;
    }

    this.onSoundSettingsCahnged = new Event();
  }

  onStartScreenHide = () => {
    this.playSFX();
  };

  playSound(type, valume = 0.5) {
    if (this.soundsMuted) return;
    return SoundManager.play(type, valume, false);
  }

  stopSound(type) {
    SoundManager.stop(type);
  }

  muteSFX() {
    this.musicMuted = true;
    setToStorage('musicMuted', 'true');
    this.pauseSFX();
    this.onSoundSettingsCahnged.call();
  }

  unmuteSFX() {
    this.musicMuted = false;
    setToStorage('musicMuted', 'false');
    this.playSFX();
    this.onSoundSettingsCahnged.call();
  }

  setSoundsMuted(value) {
    this.soundsMuted = value;
    setToStorage('soundsMuted', value);
    this.onSoundSettingsCahnged.call();
  }

  replaceSFX(nextAlias) {
    if (this.aliasSFX === nextAlias) return;
    const prevSound = SoundManager.getSound(this.aliasSFX);
    if (!prevSound.isPlaying){
      this.aliasSFX = nextAlias;
      return;
    }

    TweenMax.killTweensOf(prevSound);
    TweenMax.to(prevSound, 0.5, { volume: 0, onComplete: () => {
      SoundManager.stop(this.aliasSFX);
      this._playNextSFX(nextAlias);
    } });
  }

  _playNextSFX(nextAlias) {
    this.aliasSFX = nextAlias;
    SoundManager.play(this.aliasSFX, this.SFXVolume, true);
    const sound = SoundManager.getSound(this.aliasSFX);
    TweenMax.killTweensOf(sound);
    sound.volume = 0;
    TweenMax.to(sound, 1.5, { volume: this.SFXVolume });
  }

  playTotalWin(volume = 0.5) {
    if (this.soundsMuted) return;
    const sfx = SoundManager.getSound(this.aliasSFX);
    TweenMax.killTweensOf(sfx);
    TweenMax.to(sfx, 0.5, { volume: this.SFXVolume / 2 });
    SoundManager.play(eSoundType.EST_TOTAL_WIN, volume);
  }

  stopTotalWin() {
    SoundManager.stop(eSoundType.EST_TOTAL_WIN);
    const sfx = SoundManager.getSound(this.aliasSFX);
    TweenMax.to(sfx, 0.5, { volume: this.SFXVolume });
  }

  playSFX = () => {
    if (this.musicMuted) return;
    const sound = SoundManager.getSound(this.aliasSFX);
    if (sound.isPlaying) return;
    this.musicMuted = false;
    setToStorage('musicMuted', 'false');
    SoundManager.play(this.aliasSFX, this.SFXVolume, true);
    SoundManager.play(eSoundType.EST_NOISE_HALL, 0.2, true);
  };

  pauseSFX() {
    SoundManager.pause(this.aliasSFX, this.SFXVolume, true);
    SoundManager.pause(eSoundType.EST_NOISE_HALL, 0.2, true);
  }
}

export default new ControllerSounds();
