import FlashLib from 'flashlib';
import { TimelineMax } from 'gsap';

export default class ControllerBlackoutFull extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.alpha = 0;
  }

  show(needShow, duration = 1, delay = 0, alpha = 0.9) {
    const _alpha = alpha;
    return new Promise((resolve, reject) => {
      const animation = new TimelineMax();
      const alpha = needShow ? _alpha : 0;
      this.alpha = 0;
      animation.to(this, duration, { alpha, delay, onComplete: () => resolve() });
    });
  }
}
