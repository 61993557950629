import BaseTournamentController from '../tournaments/baseTournamentController';

export default class ControllerTournamentsStartScreenHeader extends BaseTournamentController {
  constructor({ container, ...props }) {
    super({ ...props });

    this._eButtonsTypes = {
      EBT_CLOSE: 'close_button',
    };
    this._eEventTypes = {
      CLOSE_CLICK: this.getEventName(this._eButtonsTypes.EBT_CLOSE),
    };
    this._eElementsTypes = {
      EET_CLOSE_BUTTON: this._eButtonsTypes.EBT_CLOSE,
    };

    this.init(container);
  }

  _getMarkup() {
    return `<div class="system-ui-container__tournaments_main__header">
          <div>
            <tournaments-logo class="tournament_logo"/>
          </div>

          <div>
            <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_CLOSE_BUTTON]} class="system-ui-container__close_button">
              <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M20.9989 51.3331H34.9989C46.6655 51.3331 51.3322 46.6664 51.3322 34.9997V20.9997C51.3322 9.33305 46.6655 4.66638 34.9989 4.66638H20.9989C9.33219 4.66638 4.66553 9.33305 4.66553 20.9997V34.9997C4.66553 46.6664 9.33219 51.3331 20.9989 51.3331Z"
                    fill="#17142A" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M21.3965 34.6032L34.6032 21.3965" stroke="white" stroke-width="2.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                <path d="M34.6032 34.6032L21.3965 21.3965" stroke="white" stroke-width="2.5" stroke-linecap="round"
                      stroke-linejoin="round" />
              </svg>
            </button>
          </div>
        </div>`
  }
}
