export default {
  EBAT_IDLE_0: 'idle_1',
  EBAT_IDLE_1: 'idle_2',
  EBAT_BIG_WIN: 'big_win',
  EBAT_MEGA_WIN: 'mega_win',
  EBAT_WIN_MISHIT_0: 'no_win_mishit_1',
  EBAT_WIN_MISHIT_1: 'no_win_mishit_2',
  EBAT_WIN_MISHIT_2: 'no_win_mishit_3',
  EBAT_WIN_MISHIT_3: 'no_win_mishit_4',
  EBAT_WIN_LOW_0: 'win_low_1',
  EBAT_WIN_LOW_1: 'win_low_2',
  EBAT_WIN_LOW_2: 'win_low_3',
};