import ControllerSounds from './sounds/controllerSounds';
import { store } from '../index';
import { eScreenMode } from '../enums';
import actions from '../redux/game/actions';
import selectors from '../redux/game/selectors';
import { whiteBeigeGradient } from '../fontStyles';
import { initUi } from './ui/initUI';

export default class ControllerStartScreen {
  constructor(main) {
    this.startScreen = main.getChildByName('startScreen');
    this.background = this.startScreen.getChildByName('bg');
    this.portretBackground = this.startScreen.getChildByName('bg_portret');
    this.isPortrait = false;

    this.startScreen.interactive = true;
    this.slidesBlock = this.startScreen.getChildByName('slides');
    this.buttonStart = this.startScreen.getChildByName('buttonStart');
    this.leftButton = this.slidesBlock.getChildByName('left_btn');
    this.rightButton = this.slidesBlock.getChildByName('right_btn');
    this.leftButton.buttonMode = true;
    this.rightButton.buttonMode = true;
    this.leftButton.interactive = true;
    this.rightButton.interactive = true;
    this.buttonStart.on('pointertap', this.onButtonStartClicked);
    this.leftButton.on('pointertap', this.decrementSlide);
    this.rightButton.on('pointertap', this.incrementSlide);

    this.dots = [];
    this.activeFrame = 1;
    this.dotsContainer = this.startScreen.getChildByName('dots_container');
    for (let i = 0; i < 3; i++) {
      const dot = this.dotsContainer.getChildByName(`dot_${i}`);
      dot.onClick = () => this.onClickDot(i);
      this.dots.push(dot);
    }
    this.onClickDot(0);

    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
    this.background.interactive = true;
    this.background.pointerdown = this.onPointerDown;
    if (PIXI.utils.isMobile.any) {
      this.portretBackground.interactive = true;
      this.portretBackground.pointerdown = this.onPointerDown;
      this.portretBackground.children[1].alpha = 0.8;
    }

    // document.documentElement.addEventListener('touchstart', (e)=>console.log("start", e));
    // document.documentElement.addEventListener('touchend', (e)=>console.log("end", e));
  }

  onPointerDown = (e) => {
    const background = this.isPortrait ? this.portretBackground : this.background;
    const startTouchPoint = e.data.getLocalPosition(background);
    background.pointerup = (event)=>{
      const endTouchPoint = event.data.getLocalPosition(background);
      this.checkSwipe(startTouchPoint, endTouchPoint);
    };

  }
  checkSwipe = (startPoint, endPoint) => {
    const distance = endPoint.x - startPoint.x;
    if (distance > 20) {
      this.decrementSlide();
    } else if (distance < -20) {
      this.incrementSlide();
    }
  }

  onResize = (data) => {
    if (data.isMobile && data.isLandscape) {
      this.isPortrait = false;

      this.startScreen.goToFrame(1);
      this.slidesBlock = this.startScreen.getChildByName('slides');
      this.slidesBlock.goToFrame(this.activeFrame + 1);
      this.setTextStile();


      this.background.visible = true;
      this.portretBackground.visible = false;

      this.dotsContainer.y = this.dotsContainer.displayData.y;
      this.buttonStart.y = this.buttonStart.displayData.y ;


      this.leftButton.off('pointertap', this.decrementSlide);
      this.rightButton.off('pointertap', this.incrementSlide);
      this.leftButton = this.slidesBlock.getChildByName('left_btn');
      this.rightButton = this.slidesBlock.getChildByName('right_btn');
      this.leftButton.on('pointertap', this.decrementSlide);
      this.rightButton.on('pointertap', this.incrementSlide);
    } else if (data.isMobile && data.isPortrait) {
      this.isPortrait = true;
      this.startScreen.goToFrame(2);
      this.slidesBlock = this.startScreen.getChildByName('slides');
      this.slidesBlock.goToFrame(this.activeFrame + 1);
      this.setTextStile();


      this.background.visible = false;
      this.portretBackground.visible = true;

      this.dotsContainer.y = this.dotsContainer.displayData.y + 150;
      this.buttonStart.y = this.buttonStart.displayData.y + 170;

    }
  }

  onClickDot = (index) => {
    this.setActiveSlide(index);
  }

  setActiveSlide = (frame) => {
    this.activeFrame = frame;
    this.slidesBlock.goToFrame(this.activeFrame + 1);
    for (let i = 0; i < this.dots.length; i++) {
      this.dots[i].enabled = i !== frame ;
    }
  }

  incrementSlide = () => {
    this.activeFrame = (this.activeFrame + 1) % this.dots.length;
    this.setActiveSlide(this.activeFrame);
    this.setTextStile();
  }

  decrementSlide = () => {
    this.activeFrame = Math.abs((this.activeFrame - 1 + this.dots.length) % this.dots.length);
    this.setActiveSlide(this.activeFrame);
    this.setTextStile();
  }

  setTextStile = () => {
    for (let i = 0; i < 2; i++) {
      const text = this.slidesBlock.getChildByName('slide_4')?.getChildByName(`start_screen_2_bonus_count_${i}`);
     if (text) {
       text.style = {...text.style, ...whiteBeigeGradient}
     }
    }
  }

  onButtonStartClicked = () => {
    this.startScreen.visible = false;
    ControllerSounds.playSFX();
    ControllerSounds.onStartScreenHide();
    const bonusGameShowed = selectors.getBonusGameShowed(store.getState());
    bonusGameShowed
      ? store.dispatch(actions.setScreenMode(eScreenMode.BONUS_GAME))
      : store.dispatch(actions.setScreenMode(eScreenMode.GAME));
    window.OPWrapperService.freeBetsController.show();
    initUi();
  };
}
