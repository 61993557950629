import BaseInterface from '../baseInterface';

export default class PlayerUIInterface extends BaseInterface {

  handleModelChange({ tournamentsInfo }) {
    const hasActiveTournaments = tournamentsInfo && tournamentsInfo.active && Boolean(tournamentsInfo.active.length);
    const controllerTournamentLabel = this.controllers[this.controllerTypes.ECT_TOURNAMENTS_LABEL];

    if (hasActiveTournaments) {
      controllerTournamentLabel.show();
    } else {
      controllerTournamentLabel.hide();
    }
  }

  setAvailableBets(bets) {
    const betsController = this.controllers[this.controllerTypes.ECT_BET];
    if (betsController) betsController.setBets(bets);

    const betsTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    if (betsTableController) {
      betsTableController.setBets(bets);

      const maxBetIndex = betsTableController.maxIndex;
      this.setRecentBets([0, Math.floor(maxBetIndex / 2), maxBetIndex]);
    }
  }

  setRecentBets(recentBets) {
    const betsTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    if (betsTableController) betsTableController.setRecentBets(recentBets)
  }

  setCurrentBetIndex(betIndex) {
    const betsController = this.controllers[this.controllerTypes.ECT_BET];
    if (betsController) betsController.setCurrentBetIndex(betIndex);

    const betsTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    if (betsTableController) betsTableController.setCurrentBetIndex(betIndex)
  }

  setBalance(value) {
    const balanceController = this.controllers[this.controllerTypes.ECT_BALANCE];
    if (balanceController) balanceController.setValue(value);
  }

  setWin(value) {
    const winController = this.controllers[this.controllerTypes.ECT_WIN];
    if (winController) winController.setValue(value);
  }

  setLastWin(value) {
    const winController = this.controllers[this.controllerTypes.ECT_WIN];
    if (winController) winController.lastWin = value;
  }

  showTotalWin(value) {
    const totalWinContainer = this.controllers[this.controllerTypes.ECT_TOTAL_WIN];
    if (totalWinContainer) totalWinContainer.show(value);
  }

  hideTotalWin() {
    const totalWinContainer = this.controllers[this.controllerTypes.ECT_TOTAL_WIN];
    if (totalWinContainer) totalWinContainer.hide();
  }

  setTotalWin(value) {
    const totalWinContainer = this.controllers[this.controllerTypes.ECT_TOTAL_WIN];
    if (totalWinContainer) totalWinContainer.setValue(value);
  }

  showFreeSpins(count, text) {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (spinController) spinController.showFreespins(count, text);
  }

  hideFreeSpins() {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (spinController) spinController.hideFreespins();
  }

  setFreeSpinsCount(count) {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (spinController) spinController.freespinsCount = count;
  }

  toggleSpinButton(enabled) {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (!spinController) return;

    if (enabled) {
      spinController.unblock(spinController.buttonsTypes.EBT_SPIN);
    } else {
      spinController.block(spinController.buttonsTypes.EBT_SPIN);
    }
  }

  toggleStopButton(enabled) {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (!spinController) return;

    if (enabled) {
      spinController.unblock(spinController.buttonsTypes.EBT_STOP);
    } else {
      spinController.block(spinController.buttonsTypes.EBT_STOP);
    }
  }

  toggleAutoPlayButton(enabled) {
    const autoPlayController = this.controllers[this.controllerTypes.ECT_AUTO_SPIN];
    if (!autoPlayController) return;

    if (enabled) {
      autoPlayController.unblock();
    } else {
      autoPlayController.block();
    }
  }

  toggleMenuButton(enabled) {
    const menuController = this.controllers[this.controllerTypes.ECT_MENU];
    if (!menuController) return;

    if (enabled) {
      menuController.unblock();
    } else {
      menuController.block();
    }
  }

  toggleInfoButton(enabled) {
    const infoController = this.controllers[this.controllerTypes.ECT_INFO];
    if (!infoController) return;

    if (enabled) {
      infoController.unblock();
    } else {
      infoController.block();
    }
  }

  toggleMaxBetButton(enabled) {
    const maxBetController = this.controllers[this.controllerTypes.ECT_MAX_BET];
    if (!maxBetController) return;

    if (enabled) {
      maxBetController.unblock();
    } else {
      maxBetController.block();
    }
  }

  toggleTournamentsLabel(enabled) {
    const tournamentsLabelController = this.controllers[this.controllerTypes.ECT_TOURNAMENTS_LABEL];

    if (enabled) {
      tournamentsLabelController.unblock();
    } else {
      tournamentsLabelController.block();
    }
  }

  toggleBetSelectors(enabled) {
    const betController = this.controllers[this.controllerTypes.ECT_BET];
    const betSelectController = this.controllers[this.controllerTypes.ECT_BET_SELECT];
    const betSelectorTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    [betController, betSelectController, betSelectorTableController].forEach(controller => {
      if (!controller) return;

      if (enabled) {
        controller.unblock();
      } else {
        controller.block();
      }
    });
  }

  showSpinButton() {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (spinController) spinController.showSpinButton();
  }

  showTournamentsLabel() {
    const tournamentsLabelController = this.controllers[this.controllerTypes.ECT_TOURNAMENTS_LABEL];
    if (tournamentsLabelController) tournamentsLabelController.show();
  }

  hideTournamentsLabel() {
    const tournamentsLabelController = this.controllers[this.controllerTypes.ECT_TOURNAMENTS_LABEL];
    if (tournamentsLabelController) tournamentsLabelController.hide();
  }

  showStopButton() {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (spinController) spinController.showStopButton();
  }

  setAutoPlayButtonActive(value) {
    const autoPlayController = this.controllers[this.controllerTypes.ECT_AUTO_SPIN];
    if (autoPlayController) autoPlayController.toggleActive(value);
  }

  setSoundMuted(value) {
    const soundController = this.controllers[this.controllerTypes.ECT_SOUND];
    if (soundController) soundController.setMuted(value);
  };

  closePopups() {
    this._Controller.hideOverlay();
    const betsTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    const betsSelectController = this.controllers[this.controllerTypes.ECT_BET_SELECT];
    // const honestyController = this.controllers[this.controllerTypes.ECT_HONESTY];
    if (betsTableController) betsTableController.hide();
    if (betsSelectController) betsSelectController.reset();
    // if (honestyController) honestyController.hide();
  }

  get spinButtonEnabled() {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    return spinController && !spinController.isBlocked(spinController.buttonsTypes.EBT_SPIN);
  }

  get stopButtonEnabled() {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    return spinController && !spinController.isBlocked(spinController.buttonsTypes.EBT_STOP);
  }

  get tournamentLabelEnabled() {
    const controllerTournamentLabel = this.controllers[this.controllerTypes.ECT_TOURNAMENTS_LABEL];
    return controllerTournamentLabel && !controllerTournamentLabel.isHidden && !controllerTournamentLabel.isBlocked(controllerTournamentLabel.buttonsTypes.EBT_BUTTON_LABEL);
  }
}
