import Particle from './particle';

const defaultConfig = {
  x0min: 0, // px
  x0max: 0, // px
  y0min: 0, // px
  y0max: 0, // px
  scaleStart: 1,
  scaleEnd: 1,
  angle0From: 0,
  angle0To: 0,
  angleVelocityMin: 0,
  angleVelocityMax: 0,
  v0min: 1, // px / milliseconds
  v0max: 1, // px / milliseconds
  gravityX: 0,
  gravityY: 1,
  mass: 0.05, // ~kg
  drag: 1 / 50,
  lifetimeMin: 1000, // milliseconds
  lifetimeMax: 1000, // milliseconds
  count: 50,
  box: {
    x: 0,
    y: 0,
  },
  leftBound: false,
  topBound: false,
  rightBound:false,
  bottomBound: false,
};

const defaultParticleInfo = {
  texture: undefined,
  animationType: undefined,
  animationName: undefined,
};
export default class ParticleSystem {
  constructor(container, particleInfo = defaultParticleInfo, config = defaultConfig) {
    this.container = container;
    this.particleInfo = particleInfo;
    this.config = {
      ...defaultConfig,
      ...config,
      box: {
        ...defaultConfig.box,
        width: this.container.width,
        height: this.container.height,
        ...config.box,
      },
    };
    if (this.particleInfo.texture) {
      this.particleContainer = new PIXI.ParticleContainer(config.count, {
        position: true,
        scale: true,
        rotation: true,
      });
    } else {
      this.particleContainer = new PIXI.Container();
    }

    this.particleContainer.x = this.config.box.x;
    this.particleContainer.y = this.config.box.y;
    this.particleContainer.width = this.config.box.width;
    this.particleContainer.height = this.config.box.height;
    this.container.addChild(this.particleContainer);
    this.particles = [];
    this.start();
  }
  start = () => {
    if (this.isStarted) return;
    this.timeStart = Date.now();
    for (let index = 0; index <= this.config.count; index++) {
      this.particles.push(new Particle(this.particleContainer, this.config, this.timeStart, this.particleInfo));
    }
    this.isStarted = true;
  }
  pause = () => {
    for (let particle of this.particles) {
      particle.pause();
    }
    this.particles = [];
    this.isStarted = false;
  }
  stop = () => {
    for (let particle of this.particles) {
      particle.stop();
    }
    this.particles = [];
    this.isStarted = false;
  }
}

