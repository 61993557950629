export default {
  EMA_X2_IDLE: 'x2_idle',
  EMA_X3_IDLE: 'x3_idle',
  EMA_X5_IDLE: 'x5_idle',

  EMA_X2_WIN: 'х2_win',
  EMA_X3_WIN: 'х3_win',
  EMA_X5_WIN: 'х5_win',
  EMA_X20_WIN: 'x20_win',
  EMA_X50_WIN: 'x50_win',
  EMA_X150_WIN: 'x150_win',

  EMA_EXIT_LEFT_IDLE: 'exit_left',
  EMA_EXIT_RIGHT_IDLE: 'exit_right',

  EMA_EXIT_LEFT_WIN: 'exit_left',
  EMA_EXIT_RIGHT_WIN: 'exit_right',

  EMA_RING_IDLE: 'ring_light_idle',
  EMA_RING_WIN: 'ring_light_win_universal',
};
