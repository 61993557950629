
import FlashLib from 'flashlib';

import { TweenMax, TimelineLite } from 'gsap';
import { exponentiationEaseOut, linear } from '../../utils/JSAnimations';
import { eGameState } from '../../redux/game/reducer';
import selectors from '../../redux/game/selectors';
import { store, storeObserver } from '../../index';
import actions from '../../redux/game/actions';
import { requestNewGame } from '../../api/rest';
import { whiteBeigeGradient } from '../../fontStyles';
import * as PIXI from 'pixi.js';
import clientServerFieldIndexs from './clientServerFieldIndexs';
import serverClientFieldIndexs from './serverClientFieldIndexs';
import controllerSounds, { eSoundType } from '../sounds/controllerSounds';

export default class ControllerBonusGameField extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.defaultField = [
      0, 1, 3, 5, 2,
      0, 1, 4, 3, 5,
      0, 1, 10, 2, 4,
      0, 3, 5, 2, 4
    ];
    this.exitPositions = [0, 5, 10, 15];

    this.init();
    this.gameState = storeObserver.addListenerAndGetValue( selectors.getGameState, (state) => this.gameState = state);
  }

  init = () => {
    this.symbols = [];
    this.multipliers = [];
    for (let i = 0; i < 20; i++) {
      const symbol = this.getChildByName(`symbol_${i}`);
      const multipliyer = symbol.getChildByName('multiplier');
      if (multipliyer) {
        multipliyer.style = { ...multipliyer.style, ...whiteBeigeGradient };
        this.multipliers.push(multipliyer);
      } else {
        this.multipliers.push(undefined);
      }
      this.symbols.push(symbol);
    }
    this.createField();
    this.currentSymbolIndex = 0;
    this.initAnimation();
  }

  createField = () => {
    this.arrayFealdMultipliers = [];
    const bonusGameField = selectors.getBonusGameField(store.getState());
    if (!bonusGameField || bonusGameField.length === 0) {
      this.arrayFealdMultipliers = [...this.defaultField];
    } else {
      for (let i = 0; i < 20; i++) {
        let valueField = bonusGameField[clientServerFieldIndexs[i]];
        if (!valueField) valueField = 0;
        this.arrayFealdMultipliers.push(valueField);
      }
    }

    for (let i = 0; i < 20; i++) {
      const multiplier = this.multipliers[i];
      if (multiplier) multiplier.text = `×${this.arrayFealdMultipliers[i]}`;
    }
  }

  initAnimation = () => {
    this.animationSymbols = [];
    const texture = PIXI.Texture.from('redGlow');
    for (let i = 0; i < 20; i++) {
      const sprite = new PIXI.Sprite(texture);
      sprite.blendMode = PIXI.BLEND_MODES.ADD;
      sprite.anchor.set(0.5);
      if ( !this.exitPositions.find(a => a === i)) {
        sprite.x = 8;
        sprite.y = 8;
      }
      sprite.scale.set(3);
      sprite.alpha = 0;
      this.symbols[i].addChild(sprite);
      this.animationSymbols.push(sprite);
    }
  }

  start = (stopCallback = () => {}) => {
    this.clearAnimations();
    this.createField();
    this.stopCallback = stopCallback;
    store.dispatch(actions.setGameState(eGameState.EGS_WAITING_FOR_RESPONSE));
    requestNewGame();
    this.duration = 10000000;
    this.minDuration = 3000;
    this.speed = 0.018;

    linear(this.currentSymbolIndex, this.duration * this.speed , this.cyclePerformer, this.duration, this.stop);
  }

  cyclePerformer = (value) => {
    const endValue = this.duration * this.speed;
    if (value === endValue) return;

    this.setIndexIfNew(value);

    const needStop = value / this.speed >= this.minDuration && this.gameState === eGameState.EGS_CAN_STOP;
    return needStop;
  }

  setIndexIfNew = (value) => {
    const index = (value) % 20;
    if (Math.floor(index) !== Math.floor(this.currentSymbolIndex) ) {
      this.animateSymbol(Math.floor(index));
    }
    this.currentSymbolIndex = index ;
  }

  stop = () => {
    const durationOneSymbol = 150;
    const serverTargetIndex = selectors.getTargetMultiplierIndex(store.getState());
    this.targetSymbol = (serverClientFieldIndexs[serverTargetIndex] || this.exitPositions[Math.floor(4 * Math.random())]) + 20;
    exponentiationEaseOut(
      this.currentSymbolIndex,
      this.targetSymbol,
      this.stopCyclePerformer,
      Math.abs(this.targetSymbol - this.currentSymbolIndex) * durationOneSymbol,
      this.onAnimationStoped,
      1.3);
  }

  stopCyclePerformer = (symbolIndex) => {
    this.setIndexIfNew(symbolIndex);
  }

  animateSymbol = (index) => {
    const symbol = this.symbols[index];
    TweenMax.killTweensOf(symbol.scale);
    TweenMax.to(symbol.scale, 0.1, { x: 1.2, y: 1.2, onComplete: () => {
      controllerSounds.playSound(eSoundType.EST_SELECT_MULTIPLIER);
      TweenMax.to(symbol.scale,0.2, { x: 1, y: 1 });
    } } );

    const animationSymbol = this.animationSymbols[index];
    TweenMax.killTweensOf(animationSymbol);
    TweenMax.fromTo(animationSymbol, 0.15, { alpha: 0 }, { alpha: 1, yoyo: true, repeat: 1 });

  }

  onAnimationStoped = () => {
    const symbol = this.symbols[this.currentSymbolIndex];
    TweenMax.killTweensOf(symbol.scale);
    symbol.scale.set(1);
    (new TimelineLite)
      .set(symbol.scale, { x: 1, y: 1, } )
      .call(()=> controllerSounds.playSound(eSoundType.EST_SELECT_MULTIPLIER, 0.7))
      .to(symbol.scale, 0.2, { x: 1.2, y: 1.2, repeat: 7, yoyo: true } )
      .to(symbol.scale, 0.2, { x: 2, y: 2, } )
      .call(this.doubleMultiplier)
      .to(symbol.scale, 0.2, { x: 1, y: 1, } );

    const animationSymbol = this.animationSymbols[this.currentSymbolIndex];
    TweenMax.killTweensOf(animationSymbol);
    TweenMax.fromTo(animationSymbol, 0.2, { alpha: 0 }, { alpha: 1, yoyo: true, repeat: 200 });
    this.stopCallback(this.arrayFealdMultipliers[this.currentSymbolIndex], );
  }

  doubleMultiplier = () => {
    const multiplier = this.arrayFealdMultipliers[this.currentSymbolIndex];
    const multiplierText = this.multipliers[this.currentSymbolIndex];
    if (multiplierText) multiplierText.text = `×${multiplier * 2}`;
  }

  clearAnimations = () => {
    this.symbols.forEach(symbol => {
      TweenMax.set(symbol.scale, { x: 1, y: 1 });
      TweenMax.killTweensOf(symbol.scale);
    });
    this.animationSymbols.forEach(animationSymbol => {
      TweenMax.set(animationSymbol, { alpha: 0 });
      TweenMax.killTweensOf(animationSymbol);
    });
  }

}
